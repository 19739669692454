$header-height: 70px;
.header,
.page_sub_header {
    background-color: rgba(255, 255, 255, 0.95);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: $header-height;

    .inner {
        min-width: 1024px;
        padding: 0 70px;
        //@include resize('width', 1280px);
        //@include resize-multiple('padding', 0, 42px, 0, 34px);
        width: 100%;
    }
}
.header {
    //height: 80px;
    color: #434449;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
    //&.main_theme {
    //  background-color: transparent;
    //  color: #000;
    //
    //  h1 {
    //    a {
    //      background-image: url('https://cf.realclass.co.kr/qualson/images/logo_w@2x.png');
    //      //@include retina-sprite($common-logo-w-group);
    //    }
    //  }
    //  a {
    //    &:hover {
    //      color: #000;
    //    }
    //    &:active {
    //      color: $highlight-color;
    //    }
    //  }
    //  .nav_container {
    //    .class_menu {
    //      &:after {
    //        @include retina-sprite($common-dropdown-black-group);
    //      }
    //      &.on,
    //      &:active {
    //        color: $highlight-color;
    //        &:after {
    //          @include retina-sprite($common-dropdown-on-group);
    //        }
    //      }
    //    }
    //  }
    //}
    h1,
    .nav_container {
        line-height: $header-height;
        //line-height: 80px;
    }

    h1 {
        left: 70px;
        position: absolute;
        top: 0;
        //left: 34px;
        a {
            background: url('https://cf.realclass.co.kr/qualson/images/logo_w@2x.png') no-repeat center;
            background-size: 120px auto;
            display: inline-block;
            height: 68px;
            vertical-align: top;
            width: 120px;
            //@include retina-sprite($common-logo-white-group);

            &:active {
                background-image: url('https://cf.realclass.co.kr/qualson/images/logo_green@2x.png');
                //@include retina-sprite($common-logo-green-group);
            }
        }
    }

    a {
        color: inherit;
        text-decoration: none;

        //&:hover {
        //  color: #fff;
        //}
        &:active {
            color: $highlight-color;
        }
    }
}

.nav_container {
    padding-left: 170px;
    //padding-left: 138px;
    //padding-left: $column * 1 + $gutter * 2;

    .main_nav {
        float: left;

        li + li {
            margin-left: 5px;
        }

        a {
            cursor: pointer;
            display: block;
            padding: 0 18px;
        }
        & > li > a {
            strong {
                font-size: 16px;
            }
        }
    }

    .sub_nav {
        float: right;

        li + li {
            margin-left: 20px;
        }

        a {
            cursor: pointer;
            display: block;
        }
    }

    ul {
        font-size: 0;
    }

    li {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        vertical-align: top;

        &.on {
            a {
                color: $highlight-color;
            }

            .class_menu {
                &:after {
                    //@include retina-sprite($common-dropdown-black-group);
                    @include retina-sprite($common-dropdown-on-group);
                }
            }
        }
    }

    .bt_package {
        font-size: 16px;
        font-weight: 600;

        span {
            border-bottom: 1px solid;
            border-top: 1px solid;
            display: inline-block;
            line-height: 1;
            padding: 6px 0 3px;
        }
    }

    .class_menu {
        font-size: 16px;
        font-weight: 600;

        &:after {
            content: '';
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
            @include retina-sprite($common-dropdown-black-group);
            //@include retina-sprite($common-dropdown-n-group);
        }

        &:hover {
            //color: #2bde73;

            &:after {
                @include retina-sprite($common-dropdown-black-group);
                //@include retina-sprite($common-dropdown-on-group);
                //@include retina-sprite($common-dropdown-press-group);
            }
        }

        &:active,
        &.on {
            &:after {
                @include retina-sprite($common-dropdown-on-group);
            }
        }

        &.on {
            color: $highlight-color;

            &:after {
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }

    .class_menu {
        &.on {
            & + .float_nav {
                display: block;
            }
        }
    }

    //.class_menu:hover,
    .hasSubmenu:hover {
        & + .float_nav {
            display: block;
        }
    }

    .float_nav:hover {
        display: block;
    }

    .float_nav {
        background-color: #fff;
        border: 1px solid $sub-color;
        border-radius: 5px;
        bottom: 5px;
        color: #000;
        display: none;
        position: absolute;

        li {
            display: block;

            & + li {
                margin-left: 0;
            }
        }
    }

    .sub_my_menu {
        border: 1px solid #2bde73;
        left: 50%;
        -moz-transform: translate(-50%, 100%);
        -ms-transform: translate(-50%, 100%);
        -o-transform: translate(-50%, 100%);
        -webkit-transform: translate(-50%, 100%);
        transform: translate(-50%, 100%);
        min-width: 127px;

        li {
            font-size: 14px;
            line-height: 51px;
            text-align: center;

            a {
                color: #000;
                white-space: nowrap;

                &:active {
                    color: #000;
                }
                &.onEvent {
                    &:after {
                        content: 'event';
                        @include retina-sprite($common-icon-event-group);
                        overflow: hidden;
                        text-indent: -999em;
                        vertical-align: middle;
                        margin-left: 7px;
                        display: inline-block;
                    }
                }
            }

            & + li {
                border-top: 1px solid #e6e8ea;
                margin-left: 0;
            }

            &:hover {
                background-color: $highlight-color;
            }
        }
    }

    .sub_class_menu {
        left: 0;
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);

        .inner_wrap {
            display: table;
            min-width: $gutter * 6 + $column * 6 + 20px;
            padding: 26px 0 20px;
        }

        h3,
        li {
            font-size: 14px;
            line-height: 1.4;
        }

        h3 {
            border-bottom: none;
            left: 0;
            padding: 0 10px 0 20px;
            position: absolute;
            top: 0;
            width: $gutter * 2 + $column - 10px;
        }

        li {
            a {
                border-bottom: 1px solid transparent;
                color: #757879;
                display: inline-block;
                padding: 0;
                white-space: nowrap;

                &:hover {
                    border-bottom: 1px solid $highlight-color;
                    color: $highlight-color;
                }
            }

            &.on {
                a {
                    border-bottom: 1px solid;
                    color: $highlight-color;
                }
            }

            & + li {
                margin-top: 15px;
            }
        }

        .menu_box {
            display: table-cell;
            min-width: 366px;
            padding-left: 180px;
            padding-right: 41px;
            position: relative;
            width: 50%;

            .img_area {
                bottom: -20px;
                left: 0;
                position: absolute;

                img {
                    display: block;
                    //width: 152px;
                    height: auto;
                    width: 180px;
                }
            }

            & + .menu_box {
                border-left: 1px solid #eff1f2;
            }
        }

        .lecture_menu {
            h3 {
                width: 170px;
            }

            li {
                padding-left: 51px;
                position: relative;
            }

            a {
                & + a {
                    margin-top: 14px;
                }
            }

            .new {
                position: relative;

                &:before {
                    background: url(https://cf.realclass.co.kr/qualson/images/icon_new_class@2x.png) no-repeat center;
                    background-size: 100% auto;
                    content: '';
                    height: 17px;
                    left: -12px;
                    position: absolute;
                    top: -11px;
                    width: 25px;
                }
            }

            .tag {
                border: 1px solid;
                border-radius: 8px;
                color: #757879;
                font-size: 10px;
                height: 16px;
                left: 1px;
                line-height: 16px;
                position: absolute;
                text-align: center;
                top: 1px;
                width: 33px;
            }
        }
    }
}
.page_sub_header {
    left: 0;
    position: fixed;
    top: $header-height;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
    width: 100%;
    z-index: 500;

    .inner {
        overflow: hidden;
    }

    .page_sub_title {
        float: left;
        font-size: 18px;
        font-weight: 600;
        line-height: $header-height;
    }

    .sub_button {
        border: 1px solid;
        border-radius: 8px;
        color: inherit;
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
        height: 36px;
        line-height: 35px;
        position: absolute;
        right: 70px;
        text-align: center;
        text-decoration: none;
        top: 50%;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 86px;
    }

    .user_tab {
        float: right;

        li {
            color: #a5a7aa;
            float: left;

            & + li {
                margin-left: 140px;
            }

            &.on {
                color: #000;
                font-weight: 600;

                a:after {
                    background-color: #2bde73;
                    border-radius: 50%;
                    bottom: 15px;
                    content: '';
                    height: 7px;
                    left: 50%;
                    position: absolute;
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    width: 7px;
                }
            }
        }

        a {
            color: inherit;
            cursor: pointer;
            display: block;
            font-size: 16px;
            line-height: 70px;
            position: relative;
            text-decoration: none;
        }
    }
}
.hide_header {
    .header,
    .page_sub_header,
    .class_banner,
    .real_success_story .success_head,
    &.pc_view .package_timer_group .timer_area.fixed_top .inner_timer_area {
        -moz-transform: translate3d(0, ($header-height * -1), 0);
        -ms-transform: translate3d(0, ($header-height * -1), 0);
        -o-transform: translate3d(0, ($header-height * -1), 0);
        -webkit-transform: translate3d(0, ($header-height * -1), 0);
        transform: translate3d(0, ($header-height * -1), 0);
    }
}

//.wrap {
//  position: relative;
//
//  &:after {
//    content: '';
//    width: 100%;
//    height: 100%;
//    position: fixed;
//    top: 0;
//    left: 0;
//    background: url(../images/temp7.png) no-repeat left -19px;
//    background-size: 100% auto;
//    opacity:.5;
//    z-index: 700;
//  }
//}
