@charset "UTF-8";
/*! Copyright Qualson inc. */
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_light_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_light_subset.woff) format("woff"); }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_regular_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_regular_subset.woff) format("woff"); }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_bold_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_bold_subset.woff) format("woff"); }

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

body {
  margin: 0;
  font-size: 16px;
  font-family: 'AppleSDGothicNeo', AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', 'Apple SD Gothic Neo', sans-serif;
  color: #000; }
  body br {
    font-family: AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', 'Apple SD Gothic Neo', sans-serif; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
span {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default; }

table {
  border-collapse: collapse; }

button {
  border-radius: 0;
  background-color: transparent; }

select,
button {
  cursor: pointer;
  outline: none; }
  select *,
  button * {
    cursor: pointer;
    outline: none; }

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  color: #000; }
  input[type='text']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  input[type='tel']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #858a91; }
  input[type='text']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  input[type='tel']::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #858a91; }
  input[type='text']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  input[type='tel']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #858a91; }
  input[type='text']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='password']:-moz-placeholder,
  input[type='tel']:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #858a91; }

input[type='text'],
input[type='submit'],
input[type='search'] {
  margin: 0;
  outline: none; }

input[type='submit'] {
  cursor: pointer; }

input:active,
input:focus {
  outline: none; }

a[role='button'] {
  cursor: pointer;
  outline: none; }
  a[role='button'] * {
    cursor: pointer;
    outline: none; }

select::-ms-expand {
  display: none; }

a[role='button'] {
  text-decoration: none;
  color: inherit;
  outline: none; }

img {
  border: none;
  -webkit-touch-callout: none; }

input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration {
  display: none; }

select[disabled='disabled'] {
  cursor: default !important; }

.a11y-hidden {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }
  .a11y-hidden.focusable {
    overflow: visible;
    position: static;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.ir {
  text-indent: -9999px;
  overflow: hidden; }

.txtL {
  text-align: left; }

.txtR {
  text-align: right; }

html,
body,
.wrap {
  width: 100%;
  height: 100%; }

.inner {
  width: 1280px;
  margin: 0 auto;
  position: relative; }

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501; }

.modal {
  overflow: hidden; }
  .modal:after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 502; }

[class^='popup_'] {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  background-color: rgba(0, 0, 0, 0.2); }
  [class^='popup_'].show {
    display: block; }
  [class^='popup_'] .inner {
    height: 100%; }
  [class^='popup_'].scroll_view {
    overflow-y: scroll; }
    [class^='popup_'].scroll_view .inner {
      height: auto;
      min-height: 100%;
      padding-top: 110px;
      padding-bottom: 110px; }
    [class^='popup_'].scroll_view .pop_inner {
      margin: 0 auto;
      max-width: 100%; }
  [class^='popup_'].float_view .pop_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); }
  [class^='popup_'] .btn_pop {
    padding: 22px 0 21px;
    background-color: #5900fd;
    color: #fff;
    font-size: 22px;
    border: none; }
    [class^='popup_'] .btn_pop.primary {
      background-color: #aaaeb2; }
  [class^='popup_'] .pop_title {
    border-bottom: 1px solid; }
  [class^='popup_'] .btn_close_popup {
    width: 20px;
    height: 20px;
    border: none; }

.img_round {
  border-radius: 50%;
  overflow: hidden;
  display: inline-block; }
  .img_round img {
    display: block; }

.slides {
  position: relative; }
  .slides .controller.btn_black span {
    background-image: url(../images/sprite/common.png);
    background-position: -1338px -495px;
    width: 30px;
    height: 53px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_black span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_fill span {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -1221px;
    width: 11px;
    height: 19px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_fill span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_fill:hover span {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -1187px;
    width: 11px;
    height: 19px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_fill:hover span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller button {
    position: absolute;
    display: none;
    border: none;
    background-color: transparent; }
    .slides .controller button.on {
      display: block; }
  .slides .controller span {
    display: inline-block; }
  .slides .controller .btn_next span {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.pad0 {
  padding: 0 !important; }

.header,
.page_sub_header {
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 70px; }
  .header .inner,
  .page_sub_header .inner {
    min-width: 1024px;
    padding: 0 70px;
    width: 100%; }

.header {
  color: #434449;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }
  .header h1,
  .header .nav_container {
    line-height: 70px; }
  .header h1 {
    left: 70px;
    position: absolute;
    top: 0; }
    .header h1 a {
      background: url("https://cf.realclass.co.kr/qualson/images/logo_w@2x.png") no-repeat center;
      background-size: 120px auto;
      display: inline-block;
      height: 68px;
      vertical-align: top;
      width: 120px; }
      .header h1 a:active {
        background-image: url("https://cf.realclass.co.kr/qualson/images/logo_green@2x.png"); }
  .header a {
    color: inherit;
    text-decoration: none; }
    .header a:active {
      color: #2bde73; }

.nav_container {
  padding-left: 170px; }
  .nav_container .main_nav {
    float: left; }
    .nav_container .main_nav li + li {
      margin-left: 5px; }
    .nav_container .main_nav a {
      cursor: pointer;
      display: block;
      padding: 0 18px; }
    .nav_container .main_nav > li > a strong {
      font-size: 16px; }
  .nav_container .sub_nav {
    float: right; }
    .nav_container .sub_nav li + li {
      margin-left: 20px; }
    .nav_container .sub_nav a {
      cursor: pointer;
      display: block; }
  .nav_container ul {
    font-size: 0; }
  .nav_container li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    vertical-align: top; }
    .nav_container li.on a {
      color: #2bde73; }
    .nav_container li.on .class_menu:after {
      background-image: url(../images/sprite/common.png);
      background-position: -1158px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container li.on .class_menu:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
  .nav_container .bt_package {
    font-size: 16px;
    font-weight: 600; }
    .nav_container .bt_package span {
      border-bottom: 1px solid;
      border-top: 1px solid;
      display: inline-block;
      line-height: 1;
      padding: 6px 0 3px; }
  .nav_container .class_menu {
    font-size: 16px;
    font-weight: 600; }
    .nav_container .class_menu:after {
      content: '';
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
      background-image: url(../images/sprite/common.png);
      background-position: -1108px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container .class_menu:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .nav_container .class_menu:hover:after {
      background-image: url(../images/sprite/common.png);
      background-position: -1108px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container .class_menu:hover:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .nav_container .class_menu:active:after, .nav_container .class_menu.on:after {
      background-image: url(../images/sprite/common.png);
      background-position: -1158px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container .class_menu:active:after, .nav_container .class_menu.on:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .nav_container .class_menu.on {
      color: #2bde73; }
      .nav_container .class_menu.on:after {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
  .nav_container .class_menu.on + .float_nav {
    display: block; }
  .nav_container .hasSubmenu:hover + .float_nav {
    display: block; }
  .nav_container .float_nav:hover {
    display: block; }
  .nav_container .float_nav {
    background-color: #fff;
    border: 1px solid #00c047;
    border-radius: 5px;
    bottom: 5px;
    color: #000;
    display: none;
    position: absolute; }
    .nav_container .float_nav li {
      display: block; }
      .nav_container .float_nav li + li {
        margin-left: 0; }
  .nav_container .sub_my_menu {
    border: 1px solid #2bde73;
    left: 50%;
    -moz-transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    -o-transform: translate(-50%, 100%);
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
    min-width: 127px; }
    .nav_container .sub_my_menu li {
      font-size: 14px;
      line-height: 51px;
      text-align: center; }
      .nav_container .sub_my_menu li a {
        color: #000;
        white-space: nowrap; }
        .nav_container .sub_my_menu li a:active {
          color: #000; }
        .nav_container .sub_my_menu li a.onEvent:after {
          content: 'event';
          background-image: url(../images/sprite/common.png);
          background-position: -533px -1035px;
          width: 38px;
          height: 12px;
          overflow: hidden;
          text-indent: -999em;
          vertical-align: middle;
          margin-left: 7px;
          display: inline-block; }
          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            .nav_container .sub_my_menu li a.onEvent:after {
              background-image: url(../images/sprite/common@2x.png);
              background-size: 1459px 1423px; } }
      .nav_container .sub_my_menu li + li {
        border-top: 1px solid #e6e8ea;
        margin-left: 0; }
      .nav_container .sub_my_menu li:hover {
        background-color: #2bde73; }
  .nav_container .sub_class_menu {
    left: 0;
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%); }
    .nav_container .sub_class_menu .inner_wrap {
      display: table;
      min-width: 680px;
      padding: 26px 0 20px; }
    .nav_container .sub_class_menu h3,
    .nav_container .sub_class_menu li {
      font-size: 14px;
      line-height: 1.4; }
    .nav_container .sub_class_menu h3 {
      border-bottom: none;
      left: 0;
      padding: 0 10px 0 20px;
      position: absolute;
      top: 0;
      width: 140px; }
    .nav_container .sub_class_menu li a {
      border-bottom: 1px solid transparent;
      color: #757879;
      display: inline-block;
      padding: 0;
      white-space: nowrap; }
      .nav_container .sub_class_menu li a:hover {
        border-bottom: 1px solid #2bde73;
        color: #2bde73; }
    .nav_container .sub_class_menu li.on a {
      border-bottom: 1px solid;
      color: #2bde73; }
    .nav_container .sub_class_menu li + li {
      margin-top: 15px; }
    .nav_container .sub_class_menu .menu_box {
      display: table-cell;
      min-width: 366px;
      padding-left: 180px;
      padding-right: 41px;
      position: relative;
      width: 50%; }
      .nav_container .sub_class_menu .menu_box .img_area {
        bottom: -20px;
        left: 0;
        position: absolute; }
        .nav_container .sub_class_menu .menu_box .img_area img {
          display: block;
          height: auto;
          width: 180px; }
      .nav_container .sub_class_menu .menu_box + .menu_box {
        border-left: 1px solid #eff1f2; }
    .nav_container .sub_class_menu .lecture_menu h3 {
      width: 170px; }
    .nav_container .sub_class_menu .lecture_menu li {
      padding-left: 51px;
      position: relative; }
    .nav_container .sub_class_menu .lecture_menu a + a {
      margin-top: 14px; }
    .nav_container .sub_class_menu .lecture_menu .new {
      position: relative; }
      .nav_container .sub_class_menu .lecture_menu .new:before {
        background: url(https://cf.realclass.co.kr/qualson/images/icon_new_class@2x.png) no-repeat center;
        background-size: 100% auto;
        content: '';
        height: 17px;
        left: -12px;
        position: absolute;
        top: -11px;
        width: 25px; }
    .nav_container .sub_class_menu .lecture_menu .tag {
      border: 1px solid;
      border-radius: 8px;
      color: #757879;
      font-size: 10px;
      height: 16px;
      left: 1px;
      line-height: 16px;
      position: absolute;
      text-align: center;
      top: 1px;
      width: 33px; }

.page_sub_header {
  left: 0;
  position: fixed;
  top: 70px;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
  width: 100%;
  z-index: 500; }
  .page_sub_header .inner {
    overflow: hidden; }
  .page_sub_header .page_sub_title {
    float: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 70px; }
  .page_sub_header .sub_button {
    border: 1px solid;
    border-radius: 8px;
    color: inherit;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    height: 36px;
    line-height: 35px;
    position: absolute;
    right: 70px;
    text-align: center;
    text-decoration: none;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 86px; }
  .page_sub_header .user_tab {
    float: right; }
    .page_sub_header .user_tab li {
      color: #a5a7aa;
      float: left; }
      .page_sub_header .user_tab li + li {
        margin-left: 140px; }
      .page_sub_header .user_tab li.on {
        color: #000;
        font-weight: 600; }
        .page_sub_header .user_tab li.on a:after {
          background-color: #2bde73;
          border-radius: 50%;
          bottom: 15px;
          content: '';
          height: 7px;
          left: 50%;
          position: absolute;
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          width: 7px; }
    .page_sub_header .user_tab a {
      color: inherit;
      cursor: pointer;
      display: block;
      font-size: 16px;
      line-height: 70px;
      position: relative;
      text-decoration: none; }

.hide_header .header,
.hide_header .page_sub_header,
.hide_header .class_banner,
.hide_header .real_success_story .success_head,
.hide_header.pc_view .package_timer_group .timer_area.fixed_top .inner_timer_area {
  -moz-transform: translate3d(0, -70px, 0);
  -ms-transform: translate3d(0, -70px, 0);
  -o-transform: translate3d(0, -70px, 0);
  -webkit-transform: translate3d(0, -70px, 0);
  transform: translate3d(0, -70px, 0); }

.chrome_download_banner {
  left: 0;
  position: fixed;
  top: 100px;
  width: 100%;
  z-index: 10; }
  .chrome_download_banner .banner_box {
    background-color: #fff;
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.37);
    font-size: 14px;
    line-height: 1.3;
    padding: 12px 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 270px;
    z-index: 1; }
  .chrome_download_banner .btn_group {
    margin-top: 10px;
    text-align: right; }
    .chrome_download_banner .btn_group a,
    .chrome_download_banner .btn_group button {
      display: inline-block;
      font-size: 13px;
      padding: 5px 10px;
      vertical-align: middle; }
      .chrome_download_banner .btn_group a:hover,
      .chrome_download_banner .btn_group button:hover {
        background-color: #2bde73; }
      .chrome_download_banner .btn_group a:active,
      .chrome_download_banner .btn_group button:active {
        box-shadow: inset 1px 1px 3px -1px rgba(0, 0, 0, 0.28); }
    .chrome_download_banner .btn_group a {
      text-decoration: none; }
    .chrome_download_banner .btn_group button {
      background-color: transparent;
      border: none; }

.popup_primary .pop_inner {
  background-color: #fff;
  color: #000;
  width: 400px; }

.popup_primary .pop_body {
  padding: 48px 0 42px; }
  .popup_primary .pop_body p {
    font-size: 14px;
    line-height: 1.4;
    text-align: center; }
    .popup_primary .pop_body p strong {
      display: block;
      font-size: 16px;
      font-weight: 400; }
    .popup_primary .pop_body p i {
      display: block;
      font-size: 14px;
      font-style: normal;
      margin-top: 3px; }
  .popup_primary .pop_body p + p {
    margin-top: 22px; }
  .popup_primary .pop_body .alignCenter {
    text-align: center; }

.popup_primary .pop_foot {
  padding: 0 23px 23px; }
  .popup_primary .pop_foot .btn_group {
    font-size: 0;
    margin: 0 -6px; }
    .popup_primary .pop_foot .btn_group .col {
      display: inline-block;
      padding: 0 6px;
      width: 50%; }
    .popup_primary .pop_foot .btn_group .row {
      display: block; }
      .popup_primary .pop_foot .btn_group .row + .row {
        margin-top: 13px; }
    .popup_primary .pop_foot .btn_group a {
      display: block;
      text-align: center;
      text-decoration: none; }
    .popup_primary .pop_foot .btn_group button,
    .popup_primary .pop_foot .btn_group a {
      background-color: #fff;
      border: 1px solid;
      color: #000;
      display: block;
      font-size: 18px;
      height: 52px;
      line-height: 48px;
      padding: 0;
      width: 100%; }
      .popup_primary .pop_foot .btn_group button.btn_primary,
      .popup_primary .pop_foot .btn_group a.btn_primary {
        background-color: #000;
        border: 1px solid #000;
        color: #2bde73; }

.popup_bridge .pop_inner {
  background-color: #eaedf1;
  position: relative; }

.popup_bridge .bridge_main .bg {
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%; }
  .popup_bridge .bridge_main .bg img {
    height: auto;
    left: 50%;
    position: relative;
    top: 0;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%; }

.popup_bridge .bridge_main h1 {
  font-size: 22px;
  padding-left: 2px;
  position: relative; }

.popup_bridge .bridge_main .main_banner {
  position: relative; }
  .popup_bridge .bridge_main .main_banner .thumbnail_group {
    bottom: 32px;
    font-size: 0;
    left: 20px;
    margin-top: 15px;
    position: absolute; }
  .popup_bridge .bridge_main .main_banner .img_round {
    border: 2px solid #fff;
    height: 54px;
    width: 54px; }
    .popup_bridge .bridge_main .main_banner .img_round img {
      height: 100%;
      width: 100%; }

.popup_bridge .bridge_main .inner {
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 640px;
  padding: 30px 20px 32px 25px;
  position: absolute;
  top: 0;
  width: 100%; }

.popup_bridge .bridge_main .class_list {
  margin-top: -16px;
  padding: 0 10px 8px;
  position: relative; }
  .popup_bridge .bridge_main .class_list li {
    background-color: #fff;
    border: 1px solid #ebebeb;
    position: relative; }
  .popup_bridge .bridge_main .class_list a {
    color: inherit;
    display: block;
    min-height: 122px;
    padding: 28px 174px 16px 20px;
    text-decoration: none; }
  .popup_bridge .bridge_main .class_list h2 {
    font-size: 18px; }
    .popup_bridge .bridge_main .class_list h2 strong {
      color: #00c047; }
    .popup_bridge .bridge_main .class_list h2 em {
      background-color: #00c047;
      border-radius: 10px;
      color: #fff;
      display: inline-block;
      font-size: 10px;
      font-style: normal;
      padding: 2px 5px;
      vertical-align: middle; }
  .popup_bridge .bridge_main .class_list .img_area {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto; }
    .popup_bridge .bridge_main .class_list .img_area img {
      display: block;
      height: 100%;
      width: auto; }
  .popup_bridge .bridge_main .class_list p {
    font-size: 13px;
    margin-top: 8px; }
  .popup_bridge .bridge_main .class_list li + li {
    margin-top: 3px; }

.popup_bridge .bridge_main .main_foot {
  background-color: #00c047;
  padding: 19px 20px;
  width: 100%; }
  .popup_bridge .bridge_main .main_foot .num {
    font-size: 17px; }
  .popup_bridge .bridge_main .main_foot .txt_group {
    float: left; }
  .popup_bridge .bridge_main .main_foot .btn_group {
    float: right; }
  .popup_bridge .bridge_main .main_foot h3 {
    font-size: inherit;
    font-weight: 400;
    margin-top: 8px; }
  .popup_bridge .bridge_main .main_foot .info_soldout {
    color: #ff414d;
    display: none; }
  .popup_bridge .bridge_main .main_foot button {
    border: none;
    font-size: inherit;
    height: 43px; }
  .popup_bridge .bridge_main .main_foot .btn_trial {
    background-color: #000;
    color: #2bde73;
    width: 140px; }
  .popup_bridge .bridge_main .main_foot .btn_signup {
    background-color: #ff414d;
    color: #fff;
    display: none;
    width: 125px; }
  .popup_bridge .bridge_main .main_foot.soldout {
    background-color: #b7b7b7; }
    .popup_bridge .bridge_main .main_foot.soldout .info_soldout,
    .popup_bridge .bridge_main .main_foot.soldout .btn_signup {
      display: block; }
    .popup_bridge .bridge_main .main_foot.soldout .info_trial,
    .popup_bridge .bridge_main .main_foot.soldout .btn_trial {
      display: none; }

.popup_bridge .inner .pop_inner,
.popup_signup_request .inner .pop_inner {
  width: 480px; }

.popup_bridge .pop_inner,
.popup_signup_request .pop_inner {
  position: relative; }

.popup_bridge .btn_close_popup,
.popup_signup_request .btn_close_popup {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  right: -50px;
  top: 0;
  width: 50px; }
  .popup_bridge .btn_close_popup:before,
  .popup_signup_request .btn_close_popup:before {
    content: '';
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -46px -1346px;
    width: 31px;
    height: 31px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_bridge .btn_close_popup:before,
      .popup_signup_request .btn_close_popup:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.popup_absent_remain .pop_head {
  font-size: 17px;
  font-weight: 600;
  padding: 23px 0 0;
  text-align: center; }

.popup_absent_remain .pop_body {
  line-height: 1.5; }

.popup_challenge_info .btn_close_popup {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  right: -50px;
  top: 0;
  width: 50px; }
  .popup_challenge_info .btn_close_popup:before {
    content: '';
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -46px -1346px;
    width: 31px;
    height: 31px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_challenge_info .btn_close_popup:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.popup_challenge_info .pop_inner {
  background-color: #fff;
  border-radius: 2px;
  position: relative;
  width: 550px; }

.popup_challenge_info .pop_head {
  border-bottom: 2px solid #2bde73;
  padding: 16px 0 12px;
  text-align: center; }

.popup_challenge_info .pop_body {
  font-size: 14px;
  line-height: 1.4;
  padding: 31px 32px 37px; }
  .popup_challenge_info .pop_body h4 {
    border-bottom: 2px solid #828487;
    font-size: 15px;
    margin: 35px 0 8px;
    padding: 0 2px; }
  .popup_challenge_info .pop_body ol,
  .popup_challenge_info .pop_body ul {
    margin-top: 14px; }
  .popup_challenge_info .pop_body li {
    padding-left: 9px;
    position: relative; }
    .popup_challenge_info .pop_body li:before {
      content: '-';
      left: 2px;
      position: absolute;
      top: 1px; }
    .popup_challenge_info .pop_body li + li {
      margin-top: 2px; }
  .popup_challenge_info .pop_body em {
    font-style: normal;
    font-weight: 600;
    position: relative; }
    .popup_challenge_info .pop_body em:before {
      background-color: rgba(255, 245, 104, 0.5);
      bottom: 0;
      content: '';
      height: 70%;
      left: 0;
      position: absolute;
      width: 100%; }
    .popup_challenge_info .pop_body em span {
      position: relative; }

.popup_signup_request .pop_inner {
  background-color: #fff; }

.popup_signup_request .pop_body {
  text-align: center; }
  .popup_signup_request .pop_body h2 {
    font-size: 22px;
    line-height: 1.36; }
  .popup_signup_request .pop_body h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    margin-top: 32px; }
    .popup_signup_request .pop_body h3 span {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      display: inline-block;
      padding: 2px; }
  .popup_signup_request .pop_body ul {
    margin: 20px auto 0;
    text-align: center; }
  .popup_signup_request .pop_body li {
    display: inline-block;
    min-height: 54px;
    padding: 66px 0 0;
    position: relative;
    vertical-align: top;
    width: 114px; }
    .popup_signup_request .pop_body li strong {
      display: block;
      font-size: 18px;
      font-weight: 400; }
    .popup_signup_request .pop_body li span {
      color: #626569;
      font-size: 14px; }
    .popup_signup_request .pop_body li:before {
      content: '';
      left: 50%;
      position: absolute;
      top: 0;
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%); }
  .popup_signup_request .pop_body .top_section {
    height: 372px;
    overflow: hidden;
    position: relative; }
  .popup_signup_request .pop_body .bottom_section {
    padding: 38px 0; }
  .popup_signup_request .pop_body .real_free:before {
    background-image: url(../images/sprite/common.png);
    background-position: -312px -1190px;
    width: 58px;
    height: 55px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_free:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .real_event:before {
    background-image: url(../images/sprite/common.png);
    background-position: -385px -1190px;
    width: 58px;
    height: 55px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_event:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .real_save:before {
    background-image: url(../images/sprite/common.png);
    background-position: -458px -1190px;
    width: 57px;
    height: 55px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_save:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .img_area img {
    display: block;
    height: auto;
    width: 100%; }
  .popup_signup_request .pop_body .btn_group {
    bottom: 0;
    left: 0;
    margin-top: 72px;
    padding-bottom: 12px;
    position: absolute;
    width: 100%; }
    .popup_signup_request .pop_body .btn_group .btn_signup {
      display: block;
      font-weight: 500;
      margin: 0 auto 14px;
      width: 215px; }
    .popup_signup_request .pop_body .btn_group .btn_login {
      color: #fff;
      font-size: 14px;
      text-decoration: none; }
      .popup_signup_request .pop_body .btn_group .btn_login strong {
        border-bottom: 1px solid;
        color: #2bde73;
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        line-height: 1.2; }

.slides {
  position: relative; }
  .slides .controller.btn_white span {
    background-image: url(../images/sprite/common.png);
    background-position: -1338px 0px;
    width: 43px;
    height: 82px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_white span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_gray span {
    background-image: url(../images/sprite/common.png);
    background-position: -1338px -289px;
    width: 40px;
    height: 71px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_gray span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_small span {
    background-image: url(../images/sprite/common.png);
    background-position: -1433px -1137px;
    width: 16px;
    height: 25px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_small span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.footer {
  background-color: #eceff0;
  color: #878c8d;
  font-size: 11px;
  padding: 34px 0 72px;
  position: relative;
  text-align: left; }
  .footer .inner {
    padding: 0 60px;
    width: 1024px; }
  .footer h5,
  .footer h6 {
    font-weight: 400; }
  .footer h5 {
    font-size: 0; }
    .footer h5 * {
      font-size: 11px; }
    .footer h5 strong {
      font-weight: 500; }
    .footer h5 a {
      color: inherit;
      text-decoration: none; }
    .footer h5 + h5 {
      margin-top: 5px; }
  .footer h6 {
    font-size: 11px;
    margin-top: 17px; }
    .footer h6 a {
      color: inherit;
      cursor: pointer;
      text-decoration: none; }
  .footer i {
    font-style: normal;
    margin: 0 6px; }
  .footer .txt_legal_line {
    border-top: 1px solid #dde0e1;
    margin-top: 13px;
    padding-top: 16px; }

.bt_black {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid;
  color: #000; }
  .bt_black:hover, .bt_black:active {
    background-color: rgba(0, 0, 0, 0.8);
    border: 2px solid rgba(0, 0, 0, 0.8);
    color: #fff; }

.btn_link,
.btn_bg_white,
.btn_bg_green,
.btn_bg_black {
  border: none;
  font-size: 15px;
  height: 50px;
  line-height: 49px;
  min-width: 230px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none; }

.btn_link:before {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: 16px;
  vertical-align: middle;
  width: 40px; }

.btn_bg_white,
.link_white {
  background-color: #fff;
  color: #2bde73; }
  .btn_bg_white:hover,
  .link_white:hover {
    background-color: #f7f8f8; }
  .btn_bg_white:active,
  .link_white:active {
    background-color: #e6e9ea; }

.btn_bg_green,
.link_green {
  background-color: #2bde73;
  color: #000; }
  .btn_bg_green:hover,
  .link_green:hover {
    background-color: #2ebd68; }
  .btn_bg_green:active,
  .link_green:active {
    background-color: #25ad5c; }

.btn_bg_black,
.link_black {
  background-color: #000;
  color: #fff; }
  .btn_bg_black:hover,
  .link_black:hover {
    background-color: #222; }
  .btn_bg_black:active,
  .link_black:active {
    background-color: #333; }

.link_icon_white {
  color: #fff; }
  .link_icon_white:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1069px -1190px;
    width: 40px;
    height: 20px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_white:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_icon_green {
  color: #2bde73; }
  .link_icon_green:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1124px -1190px;
    width: 40px;
    height: 20px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_green:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_icon_black {
  color: #000; }
  .link_icon_black:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1179px -1190px;
    width: 40px;
    height: 20px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_black:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_white:before {
  background: url("https://cf.realclass.co.kr/images/btn_white@2x.gif") no-repeat center;
  background-size: 40px auto; }

.link_white:hover:before, .link_white:active:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1124px -1190px;
  width: 40px;
  height: 20px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_white:hover:before, .link_white:active:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.link_green:before {
  background: url("https://cf.realclass.co.kr/images/btn_green@2x.gif") no-repeat center;
  background-size: 40px auto; }

.link_green:hover:before, .link_green:active:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1179px -1190px;
  width: 40px;
  height: 20px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_green:hover:before, .link_green:active:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.link_black:before {
  background: url("https://cf.realclass.co.kr/images/btn_black@2x.gif") no-repeat center;
  background-size: 40px auto; }

.link_black:hover:before, .link_black:active:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1069px -1190px;
  width: 40px;
  height: 20px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_black:hover:before, .link_black:active:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.bt_animation {
  background-color: transparent;
  border: none;
  display: block;
  height: 50px; }
  .bt_animation img {
    display: block; }

.btn_link.bt_animation {
  padding: 0; }
  .btn_link.bt_animation:before {
    display: none; }

.floating_banner {
  background-color: rgba(240, 240, 240, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 600; }
  .floating_banner .inner {
    height: 60px;
    text-align: center; }
  .floating_banner .img_area,
  .floating_banner .txt_area {
    display: inline-block;
    vertical-align: middle; }
  .floating_banner .img_area {
    height: 100%;
    position: relative;
    width: 92px; }
    .floating_banner .img_area img {
      bottom: 0;
      height: auto;
      left: 0;
      position: absolute;
      width: 100%; }
  .floating_banner .txt_area {
    color: #000;
    font-size: 18px; }
    .floating_banner .txt_area b {
      border-bottom: 1px solid;
      color: #2bde73;
      display: inline-block;
      font-weight: 400;
      margin: 0 4px; }
    .floating_banner .txt_area i {
      font-style: normal; }
  .floating_banner a {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.legal_line {
  bottom: 5px;
  color: #000;
  font-size: 10px;
  left: 50%;
  position: absolute;
  text-align: center;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.browser_not_supported .inner {
  max-width: 480px;
  padding: 87px 0;
  text-align: center; }

.browser_not_supported h5 {
  background: url("https://cf.realclass.co.kr/qualson/images/bg_browser_not_supported@2x.png") no-repeat center top;
  background-size: 73px auto;
  font-size: 22px;
  padding-top: 118px; }

.browser_not_supported p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 27px; }
  .browser_not_supported p strong {
    background-color: #eef0f1;
    border-radius: 7px;
    display: block;
    margin: 19px auto 20px;
    padding: 22px 0;
    width: 312px; }
  .browser_not_supported p span {
    color: #19bf5c; }

.browser_not_supported a {
  background-color: #000;
  color: #2bde73;
  display: block;
  height: 55px;
  line-height: 55px;
  margin: 40px auto 0;
  text-decoration: none;
  width: 260px; }

.popup_mission2 .pop_inner,
.popup_mission3 .pop_inner,
.popup_mission_final .pop_inner {
  padding-top: 20px !important;
  width: 621px; }

.popup_refund_info,
.popup_apply_success,
.popup_mission,
.popup_marketing,
.popup_mission2,
.popup_mission3,
.popup_mission_final {
  display: none; }
  .popup_refund_info.show,
  .popup_apply_success.show,
  .popup_mission.show,
  .popup_marketing.show,
  .popup_mission2.show,
  .popup_mission3.show,
  .popup_mission_final.show {
    display: block; }
  .popup_refund_info .pop_inner,
  .popup_apply_success .pop_inner,
  .popup_mission .pop_inner,
  .popup_marketing .pop_inner,
  .popup_mission2 .pop_inner,
  .popup_mission3 .pop_inner,
  .popup_mission_final .pop_inner {
    padding-top: 36px;
    text-align: center; }
  .popup_refund_info .pop_head h2,
  .popup_apply_success .pop_head h2,
  .popup_mission .pop_head h2,
  .popup_marketing .pop_head h2,
  .popup_mission2 .pop_head h2,
  .popup_mission3 .pop_head h2,
  .popup_mission_final .pop_head h2 {
    color: #000;
    font-size: 26px;
    font-weight: bold; }
  .popup_refund_info .pop_head h3,
  .popup_apply_success .pop_head h3,
  .popup_mission .pop_head h3,
  .popup_marketing .pop_head h3,
  .popup_mission2 .pop_head h3,
  .popup_mission3 .pop_head h3,
  .popup_mission_final .pop_head h3 {
    font-size: 24px;
    font-weight: bold; }
    .popup_refund_info .pop_head h3.title-2,
    .popup_apply_success .pop_head h3.title-2,
    .popup_mission .pop_head h3.title-2,
    .popup_marketing .pop_head h3.title-2,
    .popup_mission2 .pop_head h3.title-2,
    .popup_mission3 .pop_head h3.title-2,
    .popup_mission_final .pop_head h3.title-2 {
      color: #5cd99d; }
    .popup_refund_info .pop_head h3.title-3,
    .popup_apply_success .pop_head h3.title-3,
    .popup_mission .pop_head h3.title-3,
    .popup_marketing .pop_head h3.title-3,
    .popup_mission2 .pop_head h3.title-3,
    .popup_mission3 .pop_head h3.title-3,
    .popup_mission_final .pop_head h3.title-3 {
      color: #4acab9; }
  .popup_refund_info .pop_head h3 + p,
  .popup_apply_success .pop_head h3 + p,
  .popup_mission .pop_head h3 + p,
  .popup_marketing .pop_head h3 + p,
  .popup_mission2 .pop_head h3 + p,
  .popup_mission3 .pop_head h3 + p,
  .popup_mission_final .pop_head h3 + p {
    font-size: 16px;
    font-weight: bold;
    margin: 11px auto 15.5px; }
  .popup_refund_info .pop_head h3.title-2 + p,
  .popup_apply_success .pop_head h3.title-2 + p,
  .popup_mission .pop_head h3.title-2 + p,
  .popup_marketing .pop_head h3.title-2 + p,
  .popup_mission2 .pop_head h3.title-2 + p,
  .popup_mission3 .pop_head h3.title-2 + p,
  .popup_mission_final .pop_head h3.title-2 + p {
    color: #5cd99d; }
  .popup_refund_info .pop_head h3.title-3 + p,
  .popup_apply_success .pop_head h3.title-3 + p,
  .popup_mission .pop_head h3.title-3 + p,
  .popup_marketing .pop_head h3.title-3 + p,
  .popup_mission2 .pop_head h3.title-3 + p,
  .popup_mission3 .pop_head h3.title-3 + p,
  .popup_mission_final .pop_head h3.title-3 + p {
    color: #4acab9; }
  .popup_refund_info .pop_head p,
  .popup_apply_success .pop_head p,
  .popup_mission .pop_head p,
  .popup_marketing .pop_head p,
  .popup_mission2 .pop_head p,
  .popup_mission3 .pop_head p,
  .popup_mission_final .pop_head p {
    color: #000000;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.38;
    margin: 21px auto 18.5px;
    width: auto; }
  .popup_refund_info .pop_body,
  .popup_apply_success .pop_body,
  .popup_mission .pop_body,
  .popup_marketing .pop_body,
  .popup_mission2 .pop_body,
  .popup_mission3 .pop_body,
  .popup_mission_final .pop_body {
    padding: 0 40px 40px; }
    .popup_refund_info .pop_body.no_padding,
    .popup_apply_success .pop_body.no_padding,
    .popup_mission .pop_body.no_padding,
    .popup_marketing .pop_body.no_padding,
    .popup_mission2 .pop_body.no_padding,
    .popup_mission3 .pop_body.no_padding,
    .popup_mission_final .pop_body.no_padding {
      border-top: 1px solid #5cd99d;
      padding: 0; }
    .popup_refund_info .pop_body p.sub_content,
    .popup_apply_success .pop_body p.sub_content,
    .popup_mission .pop_body p.sub_content,
    .popup_marketing .pop_body p.sub_content,
    .popup_mission2 .pop_body p.sub_content,
    .popup_mission3 .pop_body p.sub_content,
    .popup_mission_final .pop_body p.sub_content {
      background: #f6f7f8;
      color: #9ba3a6;
      font-size: 12px;
      font-weight: normal;
      padding: 21px 0 22px;
      width: 100%; }
    .popup_refund_info .pop_body p.marketing_text,
    .popup_apply_success .pop_body p.marketing_text,
    .popup_mission .pop_body p.marketing_text,
    .popup_marketing .pop_body p.marketing_text,
    .popup_mission2 .pop_body p.marketing_text,
    .popup_mission3 .pop_body p.marketing_text,
    .popup_mission_final .pop_body p.marketing_text {
      background: #f6f7f8;
      color: #626569;
      font-size: 12.5px;
      font-weight: normal;
      line-height: 1.5;
      padding: 13.5px 8.5px 13px 11.5px;
      text-align: left; }
    .popup_refund_info .pop_body p.guide,
    .popup_apply_success .pop_body p.guide,
    .popup_mission .pop_body p.guide,
    .popup_marketing .pop_body p.guide,
    .popup_mission2 .pop_body p.guide,
    .popup_mission3 .pop_body p.guide,
    .popup_mission_final .pop_body p.guide {
      color: #9ba3a6;
      font-size: 16px;
      font-weight: normal;
      margin-top: 19px; }
    .popup_refund_info .pop_body .success-text,
    .popup_apply_success .pop_body .success-text,
    .popup_mission .pop_body .success-text,
    .popup_marketing .pop_body .success-text,
    .popup_mission2 .pop_body .success-text,
    .popup_mission3 .pop_body .success-text,
    .popup_mission_final .pop_body .success-text {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20.5px; }
    .popup_refund_info .pop_body .careful,
    .popup_apply_success .pop_body .careful,
    .popup_mission .pop_body .careful,
    .popup_marketing .pop_body .careful,
    .popup_mission2 .pop_body .careful,
    .popup_mission3 .pop_body .careful,
    .popup_mission_final .pop_body .careful {
      color: #9ba3a6;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.37;
      padding: 14.5px 20px 12.5px 16.5px;
      text-align: left; }
    .popup_refund_info .pop_body ul.study_list,
    .popup_apply_success .pop_body ul.study_list,
    .popup_mission .pop_body ul.study_list,
    .popup_marketing .pop_body ul.study_list,
    .popup_mission2 .pop_body ul.study_list,
    .popup_mission3 .pop_body ul.study_list,
    .popup_mission_final .pop_body ul.study_list {
      padding: 40px; }
      .popup_refund_info .pop_body ul.study_list li,
      .popup_apply_success .pop_body ul.study_list li,
      .popup_mission .pop_body ul.study_list li,
      .popup_marketing .pop_body ul.study_list li,
      .popup_mission2 .pop_body ul.study_list li,
      .popup_mission3 .pop_body ul.study_list li,
      .popup_mission_final .pop_body ul.study_list li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative; }
        .popup_refund_info .pop_body ul.study_list li + li,
        .popup_apply_success .pop_body ul.study_list li + li,
        .popup_mission .pop_body ul.study_list li + li,
        .popup_marketing .pop_body ul.study_list li + li,
        .popup_mission2 .pop_body ul.study_list li + li,
        .popup_mission3 .pop_body ul.study_list li + li,
        .popup_mission_final .pop_body ul.study_list li + li {
          border-top: 1px solid #f6f7f8;
          margin-top: 30px;
          padding-top: 30px; }
        .popup_refund_info .pop_body ul.study_list li.regular,
        .popup_apply_success .pop_body ul.study_list li.regular,
        .popup_mission .pop_body ul.study_list li.regular,
        .popup_marketing .pop_body ul.study_list li.regular,
        .popup_mission2 .pop_body ul.study_list li.regular,
        .popup_mission3 .pop_body ul.study_list li.regular,
        .popup_mission_final .pop_body ul.study_list li.regular {
          height: 90px; }
        .popup_refund_info .pop_body ul.study_list li .count,
        .popup_apply_success .pop_body ul.study_list li .count,
        .popup_mission .pop_body ul.study_list li .count,
        .popup_marketing .pop_body ul.study_list li .count,
        .popup_mission2 .pop_body ul.study_list li .count,
        .popup_mission3 .pop_body ul.study_list li .count,
        .popup_mission_final .pop_body ul.study_list li .count {
          color: #9ba3a6;
          font-size: 15.5px;
          font-weight: normal;
          position: absolute;
          right: 0;
          top: 40px; }
        .popup_refund_info .pop_body ul.study_list li .btn-submit,
        .popup_apply_success .pop_body ul.study_list li .btn-submit,
        .popup_mission .pop_body ul.study_list li .btn-submit,
        .popup_marketing .pop_body ul.study_list li .btn-submit,
        .popup_mission2 .pop_body ul.study_list li .btn-submit,
        .popup_mission3 .pop_body ul.study_list li .btn-submit,
        .popup_mission_final .pop_body ul.study_list li .btn-submit {
          background: #fff;
          border: 1px solid #e1e3e6;
          color: #e1e3e6;
          font-size: 16px;
          font-weight: bold;
          height: 45px;
          margin: 10px auto 0;
          position: relative;
          width: 153px; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.active,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.active,
          .popup_mission .pop_body ul.study_list li .btn-submit.active,
          .popup_marketing .pop_body ul.study_list li .btn-submit.active,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.active,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.active,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.active {
            border: solid 1px #13141a;
            color: #13141a; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.completed,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission .pop_body ul.study_list li .btn-submit.completed,
          .popup_marketing .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.completed {
            border: none;
            color: #2bde73; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_marketing .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.completed:after {
            background: url("https://cf.realclass.co.kr/qualson/images/c4e3b6af199258ce17ca126eec3a353f");
            background-size: 100% 100%;
            content: '';
            display: block;
            height: 10px;
            position: absolute;
            right: 29px;
            top: 15px;
            width: 12px; }
        .popup_refund_info .pop_body ul.study_list li .writingContents,
        .popup_apply_success .pop_body ul.study_list li .writingContents,
        .popup_mission .pop_body ul.study_list li .writingContents,
        .popup_marketing .pop_body ul.study_list li .writingContents,
        .popup_mission2 .pop_body ul.study_list li .writingContents,
        .popup_mission3 .pop_body ul.study_list li .writingContents,
        .popup_mission_final .pop_body ul.study_list li .writingContents {
          border: solid 1px #e1e3e6;
          box-sizing: border-box;
          font-size: 16px;
          padding: 10px 15px;
          width: 100%; }
        .popup_refund_info .pop_body ul.study_list li .content,
        .popup_apply_success .pop_body ul.study_list li .content,
        .popup_mission .pop_body ul.study_list li .content,
        .popup_marketing .pop_body ul.study_list li .content,
        .popup_mission2 .pop_body ul.study_list li .content,
        .popup_mission3 .pop_body ul.study_list li .content,
        .popup_mission_final .pop_body ul.study_list li .content {
          position: relative;
          text-align: left; }
          .popup_refund_info .pop_body ul.study_list li .content textarea,
          .popup_apply_success .pop_body ul.study_list li .content textarea,
          .popup_mission .pop_body ul.study_list li .content textarea,
          .popup_marketing .pop_body ul.study_list li .content textarea,
          .popup_mission2 .pop_body ul.study_list li .content textarea,
          .popup_mission3 .pop_body ul.study_list li .content textarea,
          .popup_mission_final .pop_body ul.study_list li .content textarea {
            -webkit-appearance: none;
            border: solid 1px #e1e3e6;
            border-radius: 0;
            color: #13141a;
            font-size: 16px;
            font-weight: normal;
            height: 173px;
            padding: 15px;
            resize: none;
            width: 100%; }
          .popup_refund_info .pop_body ul.study_list li .content.m20,
          .popup_apply_success .pop_body ul.study_list li .content.m20,
          .popup_mission .pop_body ul.study_list li .content.m20,
          .popup_marketing .pop_body ul.study_list li .content.m20,
          .popup_mission2 .pop_body ul.study_list li .content.m20,
          .popup_mission3 .pop_body ul.study_list li .content.m20,
          .popup_mission_final .pop_body ul.study_list li .content.m20 {
            margin: 0 55px; }
          .popup_refund_info .pop_body ul.study_list li .content input[type='file'],
          .popup_apply_success .pop_body ul.study_list li .content input[type='file'],
          .popup_mission .pop_body ul.study_list li .content input[type='file'],
          .popup_marketing .pop_body ul.study_list li .content input[type='file'],
          .popup_mission2 .pop_body ul.study_list li .content input[type='file'],
          .popup_mission3 .pop_body ul.study_list li .content input[type='file'],
          .popup_mission_final .pop_body ul.study_list li .content input[type='file'] {
            cursor: pointer;
            height: 181px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 225px; }
          .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel {
            padding-left: 32px; }
            .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level {
              background: #fff;
              border: 1px solid #e1e3e6;
              border-radius: 17px;
              color: #e1e3e6;
              cursor: pointer;
              display: inline-block;
              font-size: 17px;
              font-weight: 500;
              height: 35px;
              line-height: 35px;
              text-align: center;
              white-space: nowrap;
              width: 93px; }
              .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on {
                background: #13141a;
                border: none;
                color: #fff; }
              .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2) {
                margin: 0 6px; }
          .popup_refund_info .pop_body ul.study_list li .content .btn-upload,
          .popup_apply_success .pop_body ul.study_list li .content .btn-upload,
          .popup_mission .pop_body ul.study_list li .content .btn-upload,
          .popup_marketing .pop_body ul.study_list li .content .btn-upload,
          .popup_mission2 .pop_body ul.study_list li .content .btn-upload,
          .popup_mission3 .pop_body ul.study_list li .content .btn-upload,
          .popup_mission_final .pop_body ul.study_list li .content .btn-upload {
            background: url("https://cf.realclass.co.kr/qualson/images/db3a0ba73b721b02dea2f6a8bd0eb0ca");
            background-size: 100% 100%;
            border: none;
            height: 181px;
            width: 225px; }
            .popup_refund_info .pop_body ul.study_list li .content .btn-upload.off,
            .popup_apply_success .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission .pop_body ul.study_list li .content .btn-upload.off,
            .popup_marketing .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission2 .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission3 .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission_final .pop_body ul.study_list li .content .btn-upload.off {
              display: none; }
          .popup_refund_info .pop_body ul.study_list li .content div.pic,
          .popup_apply_success .pop_body ul.study_list li .content div.pic,
          .popup_mission .pop_body ul.study_list li .content div.pic,
          .popup_marketing .pop_body ul.study_list li .content div.pic,
          .popup_mission2 .pop_body ul.study_list li .content div.pic,
          .popup_mission3 .pop_body ul.study_list li .content div.pic,
          .popup_mission_final .pop_body ul.study_list li .content div.pic {
            background: url("https://cf.realclass.co.kr/qualson/images/6b4ef9547d72a61d95b39c1c191be7b2");
            background-size: 100% 100%;
            display: none;
            height: 181px;
            position: relative;
            width: 225px; }
            .popup_refund_info .pop_body ul.study_list li .content div.pic.on,
            .popup_apply_success .pop_body ul.study_list li .content div.pic.on,
            .popup_mission .pop_body ul.study_list li .content div.pic.on,
            .popup_marketing .pop_body ul.study_list li .content div.pic.on,
            .popup_mission2 .pop_body ul.study_list li .content div.pic.on,
            .popup_mission3 .pop_body ul.study_list li .content div.pic.on,
            .popup_mission_final .pop_body ul.study_list li .content div.pic.on {
              display: block; }
            .popup_refund_info .pop_body ul.study_list li .content div.pic.on:after,
            .popup_apply_success .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission .pop_body ul.study_list li .content div.pic.on:after,
            .popup_marketing .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission2 .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission3 .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission_final .pop_body ul.study_list li .content div.pic.on:after {
              background: url("https://cf.realclass.co.kr/qualson/images/7a5f04f260d909e238588e064dd1c6b4");
              background-size: 100% 100%;
              bottom: -10px;
              content: '';
              display: block;
              height: 30px;
              position: absolute;
              right: -10px;
              width: 30px; }
        .popup_refund_info .pop_body ul.study_list li .wrapCheckStar,
        .popup_apply_success .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission .pop_body ul.study_list li .wrapCheckStar,
        .popup_marketing .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission2 .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission3 .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission_final .pop_body ul.study_list li .wrapCheckStar {
          padding-left: 32px; }
          .popup_refund_info .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_apply_success .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_marketing .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission2 .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission3 .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission_final .pop_body ul.study_list li .wrapCheckStar .star {
            background: url("https://cf.realclass.co.kr/qualson/images/954528e062512e74608ab725c9ceaf0c");
            background-size: 100% 100%;
            cursor: pointer;
            display: inline-block;
            height: 39px;
            width: 42px; }
            .popup_refund_info .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_apply_success .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_marketing .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission2 .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission3 .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission_final .pop_body ul.study_list li .wrapCheckStar .star.on {
              background: url("https://cf.realclass.co.kr/qualson/images/b13195f80abb79e378f80dfc4ca497e8");
              background-size: 100% 100%; }
        .popup_refund_info .pop_body ul.study_list li .head,
        .popup_apply_success .pop_body ul.study_list li .head,
        .popup_mission .pop_body ul.study_list li .head,
        .popup_marketing .pop_body ul.study_list li .head,
        .popup_mission2 .pop_body ul.study_list li .head,
        .popup_mission3 .pop_body ul.study_list li .head,
        .popup_mission_final .pop_body ul.study_list li .head {
          align-items: center;
          display: flex;
          margin-bottom: 13px; }
        .popup_refund_info .pop_body ul.study_list li .icoIndex,
        .popup_apply_success .pop_body ul.study_list li .icoIndex,
        .popup_mission .pop_body ul.study_list li .icoIndex,
        .popup_marketing .pop_body ul.study_list li .icoIndex,
        .popup_mission2 .pop_body ul.study_list li .icoIndex,
        .popup_mission3 .pop_body ul.study_list li .icoIndex,
        .popup_mission_final .pop_body ul.study_list li .icoIndex {
          background-color: #5cd99d;
          border-radius: 10px;
          color: #fff;
          font-style: normal;
          height: 22px;
          line-height: 22px;
          margin-right: 10px;
          width: 22px; }
        .popup_refund_info .pop_body ul.study_list li .ico-check,
        .popup_apply_success .pop_body ul.study_list li .ico-check,
        .popup_mission .pop_body ul.study_list li .ico-check,
        .popup_marketing .pop_body ul.study_list li .ico-check,
        .popup_mission2 .pop_body ul.study_list li .ico-check,
        .popup_mission3 .pop_body ul.study_list li .ico-check,
        .popup_mission_final .pop_body ul.study_list li .ico-check {
          background: url("https://cf.realclass.co.kr/qualson/images/49ad8d1ed98ce4895f0d3d7a07f7d324");
          background-size: 100% 100%;
          display: inline-block;
          height: 22px;
          margin-right: 10px;
          width: 22px; }
          .popup_refund_info .pop_body ul.study_list li .ico-check.on,
          .popup_apply_success .pop_body ul.study_list li .ico-check.on,
          .popup_mission .pop_body ul.study_list li .ico-check.on,
          .popup_marketing .pop_body ul.study_list li .ico-check.on,
          .popup_mission2 .pop_body ul.study_list li .ico-check.on,
          .popup_mission3 .pop_body ul.study_list li .ico-check.on,
          .popup_mission_final .pop_body ul.study_list li .ico-check.on {
            background: url("https://cf.realclass.co.kr/qualson/images/18de4938438ddd21d63dde249f5ce534");
            background-size: 100% 100%; }
        .popup_refund_info .pop_body ul.study_list li .btn-group,
        .popup_apply_success .pop_body ul.study_list li .btn-group,
        .popup_mission .pop_body ul.study_list li .btn-group,
        .popup_marketing .pop_body ul.study_list li .btn-group,
        .popup_mission2 .pop_body ul.study_list li .btn-group,
        .popup_mission3 .pop_body ul.study_list li .btn-group,
        .popup_mission_final .pop_body ul.study_list li .btn-group {
          align-items: center;
          display: flex;
          margin-left: 53.5px;
          text-align: left; }
          .popup_refund_info .pop_body ul.study_list li .btn-group span.btn,
          .popup_apply_success .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission .pop_body ul.study_list li .btn-group span.btn,
          .popup_marketing .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission2 .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission3 .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission_final .pop_body ul.study_list li .btn-group span.btn {
            display: inline-block;
            height: 31px;
            margin-right: 7.5px;
            width: 59px; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_apply_success .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_marketing .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission2 .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission3 .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission_final .pop_body ul.study_list li .btn-group .btn-voice {
            background: url("https://cf.realclass.co.kr/qualson/images/2941651500c9ff8b61d7a31441f4b75d");
            background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-voice.on {
              background: url("https://cf.realclass.co.kr/qualson/images/e6dc6bfcbc8b82f0eb60ef1761d9975c");
              background-size: 100% 100%; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play,
          .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission .pop_body ul.study_list li .btn-group .btn-play,
          .popup_marketing .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play {
            background: url("https://cf.realclass.co.kr/qualson/images/cb177a4f712c54804dc33f5aca949e27");
            background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play.on {
              background: url("https://cf.realclass.co.kr/qualson/images/6906b6c8cc4dc3d666d4b4c5ce73bf00");
              background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play.stop {
              background: url("https://cf.realclass.co.kr/qualson/images/b15c2e4585b372c13a1edf7fcc656faa");
              background-size: 100% 100%; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .time,
          .popup_apply_success .pop_body ul.study_list li .btn-group .time,
          .popup_mission .pop_body ul.study_list li .btn-group .time,
          .popup_marketing .pop_body ul.study_list li .btn-group .time,
          .popup_mission2 .pop_body ul.study_list li .btn-group .time,
          .popup_mission3 .pop_body ul.study_list li .btn-group .time,
          .popup_mission_final .pop_body ul.study_list li .btn-group .time {
            color: #9ba3a6;
            font-size: 17.5px;
            font-weight: normal;
            margin-left: 5px; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .time.record,
            .popup_apply_success .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission .pop_body ul.study_list li .btn-group .time.record,
            .popup_marketing .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission2 .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission3 .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission_final .pop_body ul.study_list li .btn-group .time.record {
              color: #ff414d; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .time.play,
            .popup_apply_success .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission .pop_body ul.study_list li .btn-group .time.play,
            .popup_marketing .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission2 .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission3 .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission_final .pop_body ul.study_list li .btn-group .time.play {
              color: #2bde73; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .ico,
          .popup_apply_success .pop_body ul.study_list li .btn-group .ico,
          .popup_mission .pop_body ul.study_list li .btn-group .ico,
          .popup_marketing .pop_body ul.study_list li .btn-group .ico,
          .popup_mission2 .pop_body ul.study_list li .btn-group .ico,
          .popup_mission3 .pop_body ul.study_list li .btn-group .ico,
          .popup_mission_final .pop_body ul.study_list li .btn-group .ico {
            display: inline-block;
            height: 13px;
            margin-left: 9px;
            width: 15px; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .ico.play,
            .popup_apply_success .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission .pop_body ul.study_list li .btn-group .ico.play,
            .popup_marketing .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission2 .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission3 .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission_final .pop_body ul.study_list li .btn-group .ico.play {
              background: url("https://cf.realclass.co.kr/qualson/images/bd33639e767b311a0d4bfe2a657379b0");
              background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .ico.record,
            .popup_apply_success .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission .pop_body ul.study_list li .btn-group .ico.record,
            .popup_marketing .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission2 .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission3 .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission_final .pop_body ul.study_list li .btn-group .ico.record {
              background: url("https://cf.realclass.co.kr/qualson/images/72ce4715dbafd36494b5187ed64a1885");
              background-size: 100% 100%; }
      .popup_refund_info .pop_body ul.study_list.type2 li,
      .popup_apply_success .pop_body ul.study_list.type2 li,
      .popup_mission .pop_body ul.study_list.type2 li,
      .popup_marketing .pop_body ul.study_list.type2 li,
      .popup_mission2 .pop_body ul.study_list.type2 li,
      .popup_mission3 .pop_body ul.study_list.type2 li,
      .popup_mission_final .pop_body ul.study_list.type2 li {
        height: auto; }
    .popup_refund_info .pop_body ul.info_list li,
    .popup_apply_success .pop_body ul.info_list li,
    .popup_mission .pop_body ul.info_list li,
    .popup_marketing .pop_body ul.info_list li,
    .popup_mission2 .pop_body ul.info_list li,
    .popup_mission3 .pop_body ul.info_list li,
    .popup_mission_final .pop_body ul.info_list li {
      background: #f6f7f8;
      display: flex;
      padding: 23px 20px 21px;
      width: 317px; }
      .popup_refund_info .pop_body ul.info_list li + li,
      .popup_apply_success .pop_body ul.info_list li + li,
      .popup_mission .pop_body ul.info_list li + li,
      .popup_marketing .pop_body ul.info_list li + li,
      .popup_mission2 .pop_body ul.info_list li + li,
      .popup_mission3 .pop_body ul.info_list li + li,
      .popup_mission_final .pop_body ul.info_list li + li {
        margin-top: 10px; }
      .popup_refund_info .pop_body ul.info_list li p,
      .popup_apply_success .pop_body ul.info_list li p,
      .popup_mission .pop_body ul.info_list li p,
      .popup_marketing .pop_body ul.info_list li p,
      .popup_mission2 .pop_body ul.info_list li p,
      .popup_mission3 .pop_body ul.info_list li p,
      .popup_mission_final .pop_body ul.info_list li p {
        margin-left: 8.5px;
        text-align: left; }
    .popup_refund_info .pop_body ul.mission_list,
    .popup_apply_success .pop_body ul.mission_list,
    .popup_mission .pop_body ul.mission_list,
    .popup_marketing .pop_body ul.mission_list,
    .popup_mission2 .pop_body ul.mission_list,
    .popup_mission3 .pop_body ul.mission_list,
    .popup_mission_final .pop_body ul.mission_list {
      margin-top: 22.5px;
      padding-left: 25px; }
      .popup_refund_info .pop_body ul.mission_list li,
      .popup_apply_success .pop_body ul.mission_list li,
      .popup_mission .pop_body ul.mission_list li,
      .popup_marketing .pop_body ul.mission_list li,
      .popup_mission2 .pop_body ul.mission_list li,
      .popup_mission3 .pop_body ul.mission_list li,
      .popup_mission_final .pop_body ul.mission_list li {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        list-style-type: disc;
        text-align: left; }
        .popup_refund_info .pop_body ul.mission_list li + li,
        .popup_apply_success .pop_body ul.mission_list li + li,
        .popup_mission .pop_body ul.mission_list li + li,
        .popup_marketing .pop_body ul.mission_list li + li,
        .popup_mission2 .pop_body ul.mission_list li + li,
        .popup_mission3 .pop_body ul.mission_list li + li,
        .popup_mission_final .pop_body ul.mission_list li + li {
          margin-top: 16px; }
  .popup_refund_info .btn_group button,
  .popup_apply_success .btn_group button,
  .popup_mission .btn_group button,
  .popup_marketing .btn_group button,
  .popup_mission2 .btn_group button,
  .popup_mission3 .btn_group button,
  .popup_mission_final .btn_group button {
    background: #000 !important;
    border: none !important;
    color: #fff !important; }

.popup_mission2 .pop_inner {
  height: 550px;
  overflow: hidden;
  overflow-y: auto; }

.popup_mission3 .pop_inner,
.popup_mission_final .pop_inner {
  height: 550px;
  overflow: hidden;
  overflow-y: auto; }
  .popup_mission3 .pop_inner .pic-swiper,
  .popup_mission_final .pop_inner .pic-swiper {
    position: relative; }
  .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box,
  .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 42px;
    top: -38px; }
    .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button,
    .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      cursor: pointer;
      display: inline-block;
      height: 22px;
      width: 22px; }
      .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-prev,
      .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-prev {
        background-image: url(https://cf.realclass.co.kr/qualson/images/cc92137cc3a1b1e954b3733e67d4a824); }
      .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-next,
      .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-next {
        background-image: url(https://cf.realclass.co.kr/qualson/images/56bb9ac4b8d87a65770a727f7832df83); }
      .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button + .swiper-button,
      .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button + .swiper-button {
        margin-left: 20px; }

.pic-swiper {
  padding: 0 21px; }

.pic-swiper-container.swiper-container {
  height: 100%;
  margin: 0;
  padding: 0 25px;
  width: 100%; }

.pic-swiper-container .swiper-wrapper {
  display: flex; }
  .pic-swiper-container .swiper-wrapper .swiper-slide + .swiper-slide {
    margin-left: 18px; }
  .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item {
    position: relative;
    width: 225px; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item.rect {
      width: 180px; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .sub-text {
      color: #9ba3a6;
      font-size: 12.5px;
      font-weight: 500;
      margin-left: 2px;
      text-align: left; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content {
      position: relative;
      text-align: left; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content textarea {
        -webkit-appearance: none;
        border: solid 1px #e1e3e6;
        border-radius: 0;
        color: #13141a;
        font-size: 12.5px;
        font-weight: normal;
        height: 86.5px;
        margin-top: 3px;
        padding: 12px 4.5px 8px 13px;
        resize: none;
        width: 254.5px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content.m20 {
        margin-left: 20px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content input[type='file'] {
        cursor: pointer;
        height: 181px;
        left: 0px;
        opacity: 0;
        position: absolute;
        top: 0px;
        width: 225px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level {
        background: #fff;
        border: 1px solid #e1e3e6;
        border-radius: 10.6px;
        color: #e1e3e6;
        display: inline-block;
        font-size: 12.5px;
        font-weight: 500;
        height: 21.5px;
        line-height: 21.5px;
        text-align: center;
        white-space: nowrap;
        width: 60px; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level.on {
          background: #13141a;
          border: none;
          color: #fff; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level:nth-child(2) {
          margin: 0 6px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-upload {
        background: url("https://cf.realclass.co.kr/qualson/images/460740734450b2027a475678b3f8b449");
        background-size: 100% 100%;
        border: none;
        height: 181px;
        width: 225px; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-upload.off {
          display: none; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic {
        background-size: 100% 100%;
        display: none;
        height: 181px;
        position: relative;
        width: 225px;
        pointer-events: none; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic.on {
          display: block; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic.on:after {
          background: url("https://cf.realclass.co.kr/qualson/images/607e5b9dcda5d291ae21ceba3056ddd2");
          background-size: 100% 100%;
          bottom: -10px;
          content: '';
          display: block;
          height: 30px;
          position: absolute;
          right: -10px;
          width: 30px; }

.leave .check_list {
  margin-top: 46px;
  text-align: left; }
  .leave .check_list .row {
    padding-left: 32px;
    display: block;
    position: relative;
    line-height: 1.4; }
    .leave .check_list .row + .row {
      margin-top: 26px; }
  .leave .check_list textarea {
    width: 100%;
    height: 94px;
    padding: 7px;
    background-color: #f5f5f5;
    border: 3px solid #e5e5e5;
    border-radius: 3px;
    resize: none;
    font-size: 16px; }
  .leave .check_list .fake_radio {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #d0d3d4;
    background-color: #f4f4f4;
    border-radius: 50%; }
  .leave .check_list input[type="radio"]:checked + .fake_radio:after {
    content: '';
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #000;
    border-radius: 50%; }

.leave h3 {
  margin-top: 120px;
  font-size: 24px;
  color: #363636;
  line-height: 1.6; }
  .leave h3 strong {
    font-size: 15px;
    font-weight: 400; }

.main {
  padding-top: 70px; }

.account_form {
  width: 730px;
  padding: 0 180px;
  text-align: center; }
  .account_form h2 {
    font-size: 20px;
    color: #13141a; }
  .account_form h3 {
    margin-top: 26px;
    font-size: 18px;
    color: #687980;
    font-weight: 400; }

.input_area {
  position: relative; }
  .input_area input {
    width: 100%;
    padding: 14px 20px 13px;
    border: 2px solid #d3d8dd;
    font-size: 18px; }
    .input_area input.active {
      border: 2px solid;
      -webkit-text-fill-color: transparent;
      color: #2bde73;
      text-shadow: 0 0 0 #35383c; }
      .input_area input.active::-webkit-input-placeholder {
        -webkit-text-fill-color: initial; }
      .input_area input.active::-moz-placeholder {
        -webkit-text-fill-color: initial; }
      .input_area input.active:-ms-input-placeholder {
        -webkit-text-fill-color: initial; }
      .input_area input.active:-moz-placeholder {
        -webkit-text-fill-color: initial; }
    .input_area input.warning {
      border: 2px solid #ff414d; }
  .input_area p + p {
    margin-top: 14px; }
  .input_area .warning + .warning_message {
    display: block; }
  .input_area .warning_message {
    margin-top: 6px;
    display: none;
    font-size: 14px;
    color: rgba(255, 65, 77, 0.8); }
  .input_area .info_message {
    margin-top: 10px;
    display: none;
    width: 100%;
    color: rgba(43, 222, 115, 0.8);
    font-size: 14px;
    font-weight: 400; }
    .input_area .info_message.on {
      display: block; }

.rel_account a {
  text-decoration: none;
  color: #687980;
  font-size: 14px;
  cursor: pointer; }

.side_banner {
  margin: 155px 0 0 30px;
  float: left; }
  .side_banner .img_group {
    width: 267px;
    height: 267px;
    position: relative;
    display: inline-block;
    border: 20px solid #2bde73; }
    .side_banner .img_group img {
      position: absolute; }
      .side_banner .img_group img.rt {
        right: -9px;
        bottom: 0; }
      .side_banner .img_group img.lt {
        right: -4px;
        bottom: 0; }
      .side_banner .img_group img.jt {
        left: 0;
        bottom: 0; }
      .side_banner .img_group img.tt {
        left: -10px;
        bottom: 0; }
      .side_banner .img_group img.david {
        right: 0;
        bottom: 0; }
      .side_banner .img_group img.chloe {
        left: 0;
        bottom: 0; }
  .side_banner .txt_group {
    margin-top: 6px;
    display: block;
    text-align: right;
    font-size: 11px;
    color: #00c047; }

.btn_group button,
.btn_group a {
  width: 100%;
  height: 52px;
  padding: 0;
  font-size: 16px;
  color: #2bde73;
  background-color: #272a2e;
  border: 2px solid #35383c; }

.btn_group button {
  line-height: 48px; }

.btn_group a {
  display: inline-block;
  line-height: 50px; }

.btn_group .btn_success {
  color: #fff;
  border: 2px solid #2bde73;
  background-color: #2bde73; }

.btn_group .btn_check {
  color: #fff; }

.btn_group .btn_normal {
  color: #131517;
  border: 2px solid #131517;
  background-color: transparent; }

.btn_group .disabled {
  color: #fefeff;
  background-color: #bcc2c9;
  border: 2px solid #bcc2c9;
  cursor: default; }

.progress {
  font-size: 0; }
  .progress span {
    width: 108px;
    height: 2px;
    display: inline-block;
    text-indent: -9999px;
    overflow: hidden;
    background-color: #abb6bb; }
  .progress span + span {
    margin-left: 5px; }
  .progress .on {
    background-color: #2bde73; }

.popup_alert .pop_inner {
  width: 400px;
  background-color: #fff;
  color: #000; }

.popup_alert .pop_body {
  padding: 48px 0 42px; }

.popup_alert .pop_foot {
  padding: 0 23px 23px; }
  .popup_alert .pop_foot .btn_group {
    margin: 0 -6px;
    font-size: 0; }
    .popup_alert .pop_foot .btn_group .col {
      width: 50%;
      padding: 0 6px;
      display: inline-block; }
    .popup_alert .pop_foot .btn_group .row {
      display: block; }
      .popup_alert .pop_foot .btn_group .row + .row {
        margin-top: 13px; }
    .popup_alert .pop_foot .btn_group a {
      text-decoration: none;
      text-align: center; }
    .popup_alert .pop_foot .btn_group button,
    .popup_alert .pop_foot .btn_group a {
      font-size: 18px; }

.popup_alert .message {
  font-size: 18px;
  text-align: center;
  line-height: 1.4; }
  .popup_alert .message strong {
    margin-bottom: 30px;
    display: block;
    font-size: 26px; }
  .popup_alert .message i {
    margin-top: 3px;
    font-style: normal;
    font-size: 14px;
    display: block; }

.login_page_main {
  height: 100%;
  text-align: center;
  white-space: nowrap; }
  .login_page_main:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle; }
  .login_page_main .inner {
    display: inline-block;
    vertical-align: middle;
    white-space: normal; }

.login_form {
  float: left; }
  .login_form .classic_login_info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    margin: 0 0 38px;
    padding: 0 24px;
    color: #434449;
    font-size: 11px; }
    .login_form .classic_login_info img {
      width: 42px;
      margin-right: 10px; }
  .login_form .input_area {
    margin-top: 40px; }
  .login_form .rel_account {
    margin-top: 30px; }
  .login_form .btn_group {
    margin-top: 35px; }
  .login_form .rel_account {
    overflow: hidden; }
    .login_form .rel_account a {
      width: 150px;
      float: left;
      text-align: center; }
      .login_form .rel_account a:after {
        content: '';
        display: inline-block;
        margin-left: 10px;
        background-image: url(../images/sprite/common.png);
        background-position: -42px -1392px;
        width: 5px;
        height: 10px; }
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          .login_form .rel_account a:after {
            background-image: url(../images/sprite/common@2x.png);
            background-size: 1459px 1423px; } }
      .login_form .rel_account a + a {
        float: right; }

.signup_form {
  padding-top: 94px;
  float: left; }
  .signup_form .progress {
    margin: 36px 0 38px; }
  .signup_form .btn_group {
    margin-top: 52px; }
  .signup_form .txt_description {
    margin-top: 10px;
    color: rgba(39, 42, 46, 0.8);
    font-size: 14px;
    line-height: 1.4; }
    .signup_form .txt_description a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid; }
  .signup_form .mobile_check_area + .btn_group {
    margin-top: 58px; }

.mobile_check_row {
  position: relative;
  padding-right: 128px; }
  .mobile_check_row .btn_group {
    width: 120px;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0; }
    .mobile_check_row .btn_group button {
      font-size: 14px; }

.find_form {
  margin: 0 auto;
  padding-top: 84px; }
  .find_form h2:before {
    content: '';
    margin: 0 auto 9px;
    display: block;
    background-image: url(../images/sprite/common.png);
    background-position: -1256px -217px;
    width: 60px;
    height: 61px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .find_form h2:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .find_form h3 strong {
    word-break: break-word; }
  .find_form .input_area {
    margin-top: 45px; }
  .find_form > div > .btn_group {
    margin-top: 58px; }
  .find_form .result_id {
    margin-top: 72px;
    padding: 0 30px; }
  .find_form .result_password {
    margin-top: 96px; }

.result_id h3 span {
  position: relative;
  display: block; }
  .result_id h3 span:after {
    content: '';
    width: 30px;
    height: 2px;
    margin: 12px auto;
    display: block;
    background-color: #687980; }

.result_id h3 strong {
  color: #000;
  font-size: 22px; }

.result_id .btn_group {
  margin-top: 73px; }

.result_password h3 {
  font-size: 22px;
  color: #000;
  line-height: 1.4; }

.result_password .btn_group {
  margin-top: 70px; }

.leave {
  width: 400px;
  margin: 0 auto;
  padding: 120px 30px; }
  .leave p {
    margin-top: 46px;
    line-height: 1.4;
    font-size: 18px; }
  .leave .btn_group button,
  .leave .btn_group a {
    width: 232px;
    background-color: #fff;
    border: 2px solid #2bde73; }
  .leave .btn_group a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer; }

.marketing-agreement {
  display: flex; }
  .marketing-agreement label {
    font-size: 14px;
    font-weight: 500;
    color: #585858; }
    .marketing-agreement label a {
      text-decoration: underline;
      color: #585858; }
  .marketing-agreement input:checked + label .fake_checkbox {
    background: url("https://cf.realclass.co.kr/qualson/images/d6824e6a2de6323077d66ce79d40bf1e");
    background-size: 100% 100%; }
  .marketing-agreement .fake_checkbox {
    cursor: pointer;
    vertical-align: bottom;
    display: inline-block;
    width: 21px;
    height: 21px;
    margin-right: 17px;
    background: url("https://cf.realclass.co.kr/qualson/images/e2a9bb15f44bff67f8bcce1d73821521");
    background-size: 100% 100%; }

.marketing-text {
  margin-top: 22px;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: #707276; }
