$primary-color: #000;
$secondary-color: #13141a;
$active-color: #5900fd;
$opacity: 1;
$highlight-color: rgba(43, 222, 115, $opacity);
$sub-color: #00c047;
$dark-green: #05af3c;

$gutter: 40px;
$column: 70px;
$sidebar-width: $gutter * 3 + $column * 3;

$base-size: 16px;
$base-width: $column * 12 + $gutter * 11;

@import '_common';
@import '_header';

.home {
  .chrome_download_banner {
    .banner_box {
      //right: 170px;
    }
  }
}

.chrome_download_banner {
  left: 0;
  position: fixed;
  top: 100px;
  width: 100%;
  z-index: 10;

  .banner_box {
    background-color: #fff;
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.37);
    font-size: 14px;
    line-height: 1.3;
    padding: 12px 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 270px;
    z-index: 1;
  }

  .btn_group {
    margin-top: 10px;
    text-align: right;

    a,
    button {
      display: inline-block;
      font-size: 13px;
      padding: 5px 10px;
      vertical-align: middle;

      &:hover {
        background-color: $highlight-color;
      }

      &:active {
        box-shadow: inset 1px 1px 3px -1px rgba(0, 0, 0, 0.28);
      }
    }

    a {
      text-decoration: none;
    }

    button {
      background-color: transparent;
      border: none;
    }
  }
}

.popup_primary {
  .pop_inner {
    background-color: #fff;
    color: #000;
    width: 400px;
  }

  .pop_body {
    padding: 48px 0 42px;

    p {
      font-size: 14px;
      line-height: 1.4;
      text-align: center;

      strong {
        display: block;
        font-size: 16px;
        font-weight: 400;
      }

      i {
        display: block;
        font-size: 14px;
        font-style: normal;
        margin-top: 3px;
      }
    }

    p + p {
      margin-top: 22px;
    }

    .alignCenter {
      text-align: center;
    }
  }

  .pop_foot {
    padding: 0 23px 23px;

    .btn_group {
      font-size: 0;
      margin: 0 -6px;

      .col {
        display: inline-block;
        padding: 0 6px;
        width: 50%;
      }

      .row {
        display: block;

        & + .row {
          margin-top: 13px;
        }
      }

      a {
        display: block;
        text-align: center;
        text-decoration: none;
      }

      button,
      a {
        background-color: #fff;
        border: 1px solid;
        color: #000;
        display: block;
        font-size: 18px;
        height: 52px;
        line-height: 48px;
        padding: 0;
        width: 100%;

        &.btn_primary {
          background-color: #000;
          border: 1px solid #000;
          color: $highlight-color;
        }
      }
    }
  }
}

.popup_bridge {
  .pop_inner {
    background-color: #eaedf1;
    position: relative;
  }

  .bridge_main {
    .bg {
      height: 100%;
      left: 0;
      position: relative;
      top: 0;
      width: 100%;

      img {
        height: auto;
        left: 50%;
        position: relative;
        top: 0;
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
      }
    }

    h1 {
      font-size: 22px;
      padding-left: 2px;
      position: relative;
    }

    .main_banner {
      position: relative;

      .thumbnail_group {
        bottom: 32px;
        font-size: 0;
        left: 20px;
        margin-top: 15px;
        position: absolute;
      }

      .img_round {
        border: 2px solid #fff;
        height: 54px;
        width: 54px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .inner {
      height: 100%;
      left: 0;
      margin: 0 auto;
      max-width: 640px;
      padding: 30px 20px 32px 25px;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .class_list {
      margin-top: -16px;
      padding: 0 10px 8px;
      position: relative;

      li {
        background-color: #fff;
        border: 1px solid #ebebeb;
        position: relative;
      }

      a {
        color: inherit;
        display: block;
        min-height: 122px;
        padding: 28px 174px 16px 20px;
        text-decoration: none;
      }

      h2 {
        font-size: 18px;

        strong {
          color: $sub-color;
        }

        em {
          background-color: $sub-color;
          border-radius: 10px;
          color: #fff;
          display: inline-block;
          font-size: 10px;
          font-style: normal;
          padding: 2px 5px;
          vertical-align: middle;
        }
      }

      .img_area {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;

        img {
          display: block;
          height: 100%;
          width: auto;
        }
      }

      p {
        font-size: 13px;
        margin-top: 8px;
      }

      li {
        & + li {
          margin-top: 3px;
        }
      }
    }

    .main_foot {
      background-color: $sub-color;
      //margin-top: -81px;
      padding: 19px 20px;
      //position: absolute;
      //bottom: 0;
      //left: 0;
      width: 100%;
      //font-size: 12px;

      .num {
        font-size: 17px;
      }

      .txt_group {
        float: left;
      }

      .btn_group {
        float: right;
      }

      h3 {
        font-size: inherit;
        font-weight: 400;
        margin-top: 8px;
      }

      .info_soldout {
        color: #ff414d;
        display: none;
      }

      button {
        border: none;

        font-size: inherit;
        height: 43px;
      }

      .btn_trial {
        background-color: #000;
        color: $highlight-color;
        width: 140px;
      }

      .btn_signup {
        background-color: #ff414d;
        color: #fff;
        display: none;
        width: 125px;
      }

      &.soldout {
        background-color: #b7b7b7;

        .info_soldout,
        .btn_signup {
          display: block;
        }

        .info_trial,
        .btn_trial {
          display: none;
        }
      }
    }
  }
}

.popup_bridge,
.popup_signup_request {
  .inner {
    .pop_inner {
      width: 480px;
    }
  }

  .pop_inner {
    position: relative;
  }

  .btn_close_popup {
    background-color: transparent;
    border: none;
    height: 50px;
    position: absolute;
    right: -50px;
    top: 0;
    width: 50px;

    &:before {
      content: '';
      display: inline-block;
      @include retina-sprite($common-btn-close-popup-white-group);
    }
  }
}

.popup_absent_remain {
  .pop_head {
    font-size: 17px;
    font-weight: 600;
    padding: 23px 0 0;
    text-align: center;
  }

  .pop_body {
    line-height: 1.5;
  }
}

.popup_challenge_info {
  .btn_close_popup {
    background-color: transparent;
    border: none;
    height: 50px;
    position: absolute;
    right: -50px;
    top: 0;
    width: 50px;

    &:before {
      content: '';
      display: inline-block;
      @include retina-sprite($common-btn-close-popup-white-group);
    }
  }

  .pop_inner {
    background-color: #fff;
    border-radius: 2px;
    position: relative;
    width: 550px;
  }

  .pop_head {
    border-bottom: 2px solid #2bde73;
    padding: 16px 0 12px;
    text-align: center;
  }

  .pop_body {
    font-size: 14px;
    line-height: 1.4;
    padding: 31px 32px 37px;

    h4 {
      border-bottom: 2px solid #828487;
      font-size: 15px;
      margin: 35px 0 8px;
      padding: 0 2px;
    }

    ol,
    ul {
      margin-top: 14px;
    }

    li {
      padding-left: 9px;
      position: relative;

      &:before {
        content: '-';
        left: 2px;
        position: absolute;
        top: 1px;
      }

      & + li {
        margin-top: 2px;
      }
    }

    em {
      font-style: normal;
      font-weight: 600;
      position: relative;

      &:before {
        background-color: transparentize(#fff568, 0.5);
        bottom: 0;
        content: '';
        height: 70%;
        left: 0;
        position: absolute;
        width: 100%;
      }

      span {
        position: relative;
      }
    }
  }
}

.popup_signup_request {
  .pop_inner {
    background-color: #fff;
  }

  .pop_body {
    //padding: 76px 40px 50px;
    text-align: center;

    h2 {
      font-size: 22px;
      line-height: 1.36;
    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.36;
      margin-top: 32px;

      span {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        display: inline-block;
        padding: 2px;
      }
    }

    ul {
      margin: 20px auto 0;
      text-align: center;
    }

    li {
      display: inline-block;
      min-height: 54px;
      padding: 66px 0 0;
      position: relative;
      vertical-align: top;
      width: 114px;

      strong {
        display: block;
        font-size: 18px;
        font-weight: 400;
      }

      span {
        color: #626569;
        font-size: 14px;
      }

      &:before {
        content: '';
        left: 50%;
        position: absolute;
        top: 0;
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }

    .top_section {
      height: 372px;
      overflow: hidden;
      position: relative;
    }

    .bottom_section {
      padding: 38px 0;
    }

    .real_free {
      &:before {
        @include retina-sprite($common-real-free-group);
      }
    }

    .real_event {
      &:before {
        @include retina-sprite($common-real-event-group);
      }
    }

    .real_save {
      &:before {
        @include retina-sprite($common-real-save-group);
      }
    }

    .img_area {
      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }

    .btn_group {
      bottom: 0;
      left: 0;
      margin-top: 72px;
      padding-bottom: 12px;
      position: absolute;
      width: 100%;

      .btn_signup {
        display: block;
        font-weight: 500;
        margin: 0 auto 14px;
        width: 215px;
      }

      .btn_login {
        color: #fff;
        font-size: 14px;
        text-decoration: none;

        strong {
          border-bottom: 1px solid;
          color: $highlight-color;
          cursor: pointer;
          display: inline-block;
          font-weight: 400;
          line-height: 1.2;
        }
      }
    }
  }
}

.slides {
  position: relative;

  .controller {
    &.btn_white {
      span {
        @include retina-sprite($common-btn-prev-white-group);
      }
    }

    &.btn_gray {
      span {
        @include retina-sprite($common-btn-prev-gray-group);
      }
    }

    &.btn_small {
      span {
        @include retina-sprite($common-btn-prev-sm-group);
      }
    }
  }
}

.footer {
  background-color: #eceff0;
  color: #878c8d;
  font-size: 11px;
  padding: 34px 0 72px;
  position: relative;
  text-align: left;

  .inner {
    padding: 0 60px;
    width: 1024px;
  }

  h5,
  h6 {
    font-weight: 400;
  }

  h5 {
    font-size: 0;

    * {
      font-size: 11px;
    }

    strong {
      font-weight: 500;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    & + h5 {
      margin-top: 5px;
    }
  }

  h6 {
    font-size: 11px;
    margin-top: 17px;

    a {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
    }
  }

  i {
    font-style: normal;
    margin: 0 6px;
  }

  .txt_legal_line {
    border-top: 1px solid #dde0e1;
    margin-top: 13px;
    padding-top: 16px;
  }
}

.bt_black {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid;
  color: #000;

  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.8);
    border: 2px solid rgba(0, 0, 0, 0.8);
    color: #fff;
  }
}

.btn_link,
.btn_bg_white,
.btn_bg_green,
.btn_bg_black {
  border: none;
  font-size: 15px;
  height: 50px;
  line-height: 49px;
  min-width: 230px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
}

.btn_link {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: 16px;
    vertical-align: middle;
    width: 40px;
  }
}

.btn_bg_white,
.link_white {
  background-color: #fff;
  color: $highlight-color;

  &:hover {
    background-color: #f7f8f8;
  }

  &:active {
    background-color: #e6e9ea;
  }
}

.btn_bg_green,
.link_green {
  background-color: $highlight-color;
  color: #000;

  &:hover {
    background-color: #2ebd68;
  }

  &:active {
    background-color: #25ad5c;
  }
}

.btn_bg_black,
.link_black {
  background-color: #000;
  color: #fff;

  &:hover {
    background-color: #222;
  }

  &:active {
    background-color: #333;
  }
}

.link_icon_white {
  color: #fff;

  &:before {
    @include retina-sprite($common-icon-link-white-group);
  }
}

.link_icon_green {
  color: $highlight-color;

  &:before {
    @include retina-sprite($common-icon-link-green-group);
  }
}

.link_icon_black {
  color: #000;

  &:before {
    @include retina-sprite($common-icon-link-black-group);
  }
}

.link_white {
  &:before {
    background: url('https://cf.realclass.co.kr/images/btn_white@2x.gif') no-repeat center;
    background-size: 40px auto;
  }

  &:hover,
  &:active {
    &:before {
      @include retina-sprite($common-icon-link-green-group);
    }
  }
}

.link_green {
  &:before {
    background: url('https://cf.realclass.co.kr/images/btn_green@2x.gif') no-repeat center;
    background-size: 40px auto;
  }

  &:hover,
  &:active {
    &:before {
      @include retina-sprite($common-icon-link-black-group);
    }
  }
}

.link_black {
  &:before {
    background: url('https://cf.realclass.co.kr/images/btn_black@2x.gif') no-repeat center;
    background-size: 40px auto;
  }

  &:hover,
  &:active {
    &:before {
      @include retina-sprite($common-icon-link-white-group);
    }
  }
}

.bt_animation {
  //width: 230px;
  background-color: transparent;
  border: none;
  display: block;
  height: 50px;

  img {
    display: block;
  }
}

.btn_link.bt_animation {
  padding: 0;

  &:before {
    display: none;
  }
}

.floating_banner {
  background-color: rgba(240, 240, 240, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 600;

  .inner {
    height: 60px;
    text-align: center;
  }

  .img_area,
  .txt_area {
    display: inline-block;
    vertical-align: middle;
  }

  .img_area {
    height: 100%;
    position: relative;
    width: 92px;

    img {
      bottom: 0;
      height: auto;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .txt_area {
    color: #000;
    font-size: 18px;

    b {
      border-bottom: 1px solid;
      color: #2bde73;
      display: inline-block;
      font-weight: 400;
      margin: 0 4px;
    }

    i {
      font-style: normal;
    }
  }

  a {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.legal_line {
  bottom: 5px;
  color: #000;
  font-size: 10px;
  left: 50%;
  position: absolute;
  text-align: center;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.browser_not_supported {
  .inner {
    max-width: 480px;
    padding: 87px 0;
    text-align: center;
  }

  h5 {
    background: url('https://cf.realclass.co.kr/qualson/images/bg_browser_not_supported@2x.png') no-repeat center top;
    background-size: 73px auto;
    font-size: 22px;
    padding-top: 118px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 27px;

    strong {
      background-color: #eef0f1;
      border-radius: 7px;
      display: block;
      margin: 19px auto 20px;
      padding: 22px 0;
      width: 312px;
    }

    span {
      color: #19bf5c;
    }
  }

  a {
    background-color: #000;
    color: #2bde73;
    display: block;
    height: 55px;
    line-height: 55px;
    margin: 40px auto 0;
    text-decoration: none;
    width: 260px;
  }
}

.popup_mission2,
.popup_mission3,
.popup_mission_final {
  .pop_inner {
    padding-top: 20px !important;
    width: 621px;
  }
}

.popup_refund_info,
.popup_apply_success,
.popup_mission,
.popup_marketing,
.popup_mission2,
.popup_mission3,
.popup_mission_final {
  display: none;

  &.show {
    display: block;
  }

  .pop_inner {
    padding-top: 36px;
    text-align: center;
  }

  .pop_head {
    h2 {
      color: #000;
      font-size: 26px;
      font-weight: bold;
    }

    h3 {
      font-size: 24px;
      font-weight: bold;

      &.title-2 {
        color: #5cd99d;
      }

      &.title-3 {
        color: #4acab9;
      }
    }

    h3 + p {
      font-size: 16px;
      font-weight: bold;
      margin: 11px auto 15.5px;
    }

    h3.title-2 + p {
      color: #5cd99d;
    }

    h3.title-3 + p {
      color: #4acab9;
    }

    p {
      color: #000000;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.38;
      margin: 21px auto 18.5px;
      width: auto;
    }
  }

  .pop_body {
    padding: 0 40px 40px;

    &.no_padding {
      border-top: 1px solid #5cd99d;
      padding: 0;
    }

    p.sub_content {
      background: #f6f7f8;
      color: #9ba3a6;
      font-size: 12px;
      font-weight: normal;
      padding: 21px 0 22px;
      width: 100%;
    }

    p.marketing_text {
      background: #f6f7f8;
      color: #626569;
      font-size: 12.5px;
      font-weight: normal;
      line-height: 1.5;
      padding: 13.5px 8.5px 13px 11.5px;
      text-align: left;
    }

    p.guide {
      color: #9ba3a6;
      font-size: 16px;
      font-weight: normal;
      margin-top: 19px;
    }

    .success-text {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20.5px;
    }

    .careful {
      color: #9ba3a6;
      font-size: 12px;

      font-weight: normal;
      line-height: 1.37;
      padding: 14.5px 20px 12.5px 16.5px;
      text-align: left;
    }

    ul.study_list {
      padding: 40px;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        & + li {
          border-top: 1px solid #f6f7f8;
          margin-top: 30px;
          padding-top: 30px;
        }

        &.regular {
          height: 90px;
        }

        &.flexible {
        }

        .count {
          color: #9ba3a6;
          font-size: 15.5px;
          font-weight: normal;
          position: absolute;
          right: 0;
          top: 40px;
        }

        .btn-submit {
          background: #fff;
          border: 1px solid #e1e3e6;
          color: #e1e3e6;
          font-size: 16px;
          font-weight: bold;
          height: 45px;
          margin: 10px auto 0;
          position: relative;
          width: 153px;

          &.active {
            border: solid 1px #13141a;
            color: #13141a;
          }

          &.completed {
            border: none;
            color: #2bde73;
          }

          &.completed:after {
            background: url('https://cf.realclass.co.kr/qualson/images/c4e3b6af199258ce17ca126eec3a353f');
            background-size: 100% 100%;
            content: '';
            display: block;
            height: 10px;
            position: absolute;
            right: 29px;
            top: 15px;
            width: 12px;
          }
        }

        .writingContents {
          border: solid 1px #e1e3e6;
          box-sizing: border-box;
          font-size: 16px;
          padding: 10px 15px;
          width: 100%;
        }

        .content {
          position: relative;
          text-align: left;

          textarea {
            -webkit-appearance: none;
            border: solid 1px #e1e3e6;
            border-radius: 0;
            color: #13141a;
            font-size: 16px;
            font-weight: normal;
            height: 173px;
            padding: 15px;
            resize: none;
            width: 100%;
          }

          &.m20 {
            margin: 0 55px;
          }

          input[type='file'] {
            cursor: pointer;
            height: 181px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 225px;
          }

          .wrapCheckLevel {
            padding-left: 32px;

            .btn-level {
              background: #fff;
              border: 1px solid #e1e3e6;
              border-radius: 17px;
              color: #e1e3e6;
              cursor: pointer;
              display: inline-block;
              font-size: 17px;
              font-weight: 500;
              height: 35px;
              line-height: 35px;
              text-align: center;
              white-space: nowrap;
              width: 93px;

              &.on {
                background: #13141a;
                border: none;
                color: #fff;
              }

              &:nth-child(2) {
                margin: 0 6px;
              }
            }
          }

          .btn-upload {
            background: url('https://cf.realclass.co.kr/qualson/images/db3a0ba73b721b02dea2f6a8bd0eb0ca');

            background-size: 100% 100%;
            border: none;
            height: 181px;
            width: 225px;

            &.off {
              display: none;
            }
          }

          div.pic {
            background: url('https://cf.realclass.co.kr/qualson/images/6b4ef9547d72a61d95b39c1c191be7b2');

            background-size: 100% 100%;

            display: none;

            height: 181px;
            position: relative;
            width: 225px;

            &.on {
              display: block;
            }

            &.on:after {
              background: url('https://cf.realclass.co.kr/qualson/images/7a5f04f260d909e238588e064dd1c6b4');
              background-size: 100% 100%;
              bottom: -10px;
              content: '';
              display: block;
              height: 30px;
              position: absolute;
              right: -10px;
              width: 30px;
            }
          }
        }

        .wrapCheckStar {
          padding-left: 32px;

          .star {
            background: url('https://cf.realclass.co.kr/qualson/images/954528e062512e74608ab725c9ceaf0c');
            background-size: 100% 100%;
            cursor: pointer;
            display: inline-block;
            height: 39px;
            width: 42px;

            &.on {
              background: url('https://cf.realclass.co.kr/qualson/images/b13195f80abb79e378f80dfc4ca497e8');
              background-size: 100% 100%;
            }
          }
        }

        .head {
          align-items: center;
          display: flex;
          margin-bottom: 13px;
        }

        .icoIndex {
          background-color: #5cd99d;
          border-radius: 10px;
          color: #fff;
          font-style: normal;
          height: 22px;
          line-height: 22px;
          margin-right: 10px;
          width: 22px;
        }

        .ico-check {
          background: url('https://cf.realclass.co.kr/qualson/images/49ad8d1ed98ce4895f0d3d7a07f7d324');
          background-size: 100% 100%;
          display: inline-block;
          height: 22px;
          margin-right: 10px;
          width: 22px;

          &.on {
            background: url('https://cf.realclass.co.kr/qualson/images/18de4938438ddd21d63dde249f5ce534');
            background-size: 100% 100%;
          }
        }

        .btn-group {
          align-items: center;
          display: flex;
          margin-left: 53.5px;
          text-align: left;

          span.btn {
            display: inline-block;
            height: 31px;
            margin-right: 7.5px;
            width: 59px;
          }

          .btn-voice {
            background: url('https://cf.realclass.co.kr/qualson/images/2941651500c9ff8b61d7a31441f4b75d');
            background-size: 100% 100%;

            &.on {
              background: url('https://cf.realclass.co.kr/qualson/images/e6dc6bfcbc8b82f0eb60ef1761d9975c');
              background-size: 100% 100%;
            }
          }

          .btn-play {
            background: url('https://cf.realclass.co.kr/qualson/images/cb177a4f712c54804dc33f5aca949e27');
            background-size: 100% 100%;

            &.on {
              background: url('https://cf.realclass.co.kr/qualson/images/6906b6c8cc4dc3d666d4b4c5ce73bf00');
              background-size: 100% 100%;
            }

            &.stop {
              background: url('https://cf.realclass.co.kr/qualson/images/b15c2e4585b372c13a1edf7fcc656faa');
              background-size: 100% 100%;
            }
          }

          .time {
            color: #9ba3a6;
            font-size: 17.5px;
            font-weight: normal;
            margin-left: 5px;

            &.record {
              color: #ff414d;
            }

            &.play {
              color: #2bde73;
            }
          }

          .ico {
            display: inline-block;
            height: 13px;
            margin-left: 9px;
            width: 15px;

            &.play {
              background: url('https://cf.realclass.co.kr/qualson/images/bd33639e767b311a0d4bfe2a657379b0');
              background-size: 100% 100%;
            }

            &.record {
              background: url('https://cf.realclass.co.kr/qualson/images/72ce4715dbafd36494b5187ed64a1885');
              background-size: 100% 100%;
            }
          }
        }
      }

      &.type2 {
        li {
          height: auto;
        }
      }
    }

    ul.info_list {
      li {
        background: #f6f7f8;

        //width: 256.5px;
        display: flex;
        padding: 23px 20px 21px;
        width: 317px;

        & + li {
          margin-top: 10px;
        }

        p {
          margin-left: 8.5px;
          text-align: left;
        }
      }
    }

    ul.mission_list {
      margin-top: 22.5px;
      padding-left: 25px;

      li {
        color: #000;

        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        list-style-type: disc;
        text-align: left;

        & + li {
          margin-top: 16px;
        }
      }
    }
  }

  .btn_group {
    button {
      background: #000 !important;
      border: none !important;
      color: #fff !important;
    }
  }
}

.popup_mission2 {
  .pop_inner {
    height: 550px;
    overflow: hidden;
    overflow-y: auto;
  }
}

.popup_mission3,
.popup_mission_final {
  .pop_inner {
    height: 550px;
    overflow: hidden;
    overflow-y: auto;

    .pic-swiper {
      position: relative;
    }

    .pic-swiper-container {
      .swiper-button-box {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 42px;
        top: -38px;

        .swiper-button {
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          cursor: pointer;
          display: inline-block;
          height: 22px;
          width: 22px;

          &.swiper-button-prev {
            background-image: url(https://cf.realclass.co.kr/qualson/images/cc92137cc3a1b1e954b3733e67d4a824);
          }

          &.swiper-button-next {
            background-image: url(https://cf.realclass.co.kr/qualson/images/56bb9ac4b8d87a65770a727f7832df83);
          }

          & + .swiper-button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.pic-swiper {
  padding: 0 21px;
}

.pic-swiper-container {
  &.swiper-container {
    height: 100%;
    margin: 0;
    padding: 0 25px;
    width: 100%;
  }

  .swiper-wrapper {
    display: flex;

    .swiper-slide {
      & + .swiper-slide {
        margin-left: 18px;
      }

      .swiper-slide-item {
        position: relative;
        width: 225px;

        &.rect {
          width: 180px;
        }

        .sub-text {
          color: #9ba3a6;
          font-size: 12.5px;
          font-weight: 500;
          margin-left: 2px;
          text-align: left;
        }

        .swiper-content {
          position: relative;
          text-align: left;

          textarea {
            -webkit-appearance: none;
            border: solid 1px #e1e3e6;
            border-radius: 0;
            color: #13141a;
            font-size: 12.5px;
            font-weight: normal;
            height: 86.5px;
            margin-top: 3px;
            padding: 12px 4.5px 8px 13px;
            resize: none;
            width: 254.5px;
          }

          &.m20 {
            margin-left: 20px;
          }

          input[type='file'] {
            cursor: pointer;
            height: 181px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 225px;
          }

          .btn-level {
            background: #fff;
            border: 1px solid #e1e3e6;
            border-radius: 10.6px;
            color: #e1e3e6;
            display: inline-block;
            font-size: 12.5px;
            font-weight: 500;
            height: 21.5px;
            line-height: 21.5px;
            text-align: center;
            white-space: nowrap;
            width: 60px;

            &.on {
              background: #13141a;
              border: none;
              color: #fff;
            }

            &:nth-child(2) {
              margin: 0 6px;
            }
          }

          .btn-upload {
            background: url('https://cf.realclass.co.kr/qualson/images/460740734450b2027a475678b3f8b449');

            background-size: 100% 100%;
            border: none;
            height: 181px;
            width: 225px;

            &.off {
              display: none;
            }
          }

          div.pic {
            background-size: 100% 100%;

            display: none;

            height: 181px;

            position: relative;
            width: 225px;
            pointer-events: none;

            &.on {
              display: block;
            }

            //background: url('https://cf.realclass.co.kr/qualson/images/6b4ef9547d72a61d95b39c1c191be7b2');
            &.on:after {
              background: url('https://cf.realclass.co.kr/qualson/images/607e5b9dcda5d291ae21ceba3056ddd2');
              background-size: 100% 100%;
              bottom: -10px;
              content: '';
              display: block;
              height: 30px;
              position: absolute;
              right: -10px;
              width: 30px;
            }
          }
        }
      }
    }
  }
}
