.leave {
  .check_list {
    margin-top: 46px;
    text-align: left;

    .row {
      padding-left: 32px;
      display: block;
      position: relative;
      line-height: 1.4;

      &+.row {
        margin-top: 26px;
      }
    }
    textarea {
      width: 100%;
      height: 94px;
      padding: 7px;
      background-color: #f5f5f5;
      border: 3px solid #e5e5e5;
      border-radius: 3px;
      resize: none;
      font-size: 16px;
    }
    .fake_radio {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #d0d3d4;
      background-color: #f4f4f4;
      border-radius: 50%;
    }
    input[type="radio"]:checked+.fake_radio {
      &:after {
        content: '';
        width: 12px;
        height: 12px;
        margin: -6px 0 0 -6px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }
  h3 {
    margin-top: 120px;
    font-size: 24px;
    color: #363636;
    line-height: 1.6;

    strong {
      font-size: 15px;
      font-weight: 400;
    }
  }
}