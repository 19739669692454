//@import "_common";
@import '_base';
@import '_account';
// Vendor
@import './vendors/common-sprite';

$primary-color: #35383c !global;

.main {
    padding-top: 70px;
}

// common

.account_form {
    width: $column * 7 + $gutter * 6;
    padding: 0 ($column * 2 + $gutter * 1);
    text-align: center;

    h2 {
        font-size: 20px;
        color: $secondary-color;
    }

    h3 {
        margin-top: 26px;
        font-size: 18px;
        color: #687980;
        font-weight: 400;
    }
}
.input_area {
    position: relative;

    input {
        width: 100%;
        padding: 14px 20px 13px;
        border: 2px solid #d3d8dd;
        font-size: 18px;

        &.active {
            border: 2px solid;
            -webkit-text-fill-color: transparent;

            color: #2bde73;
            text-shadow: 0 0 0 $primary-color;

            &::-webkit-input-placeholder {
                -webkit-text-fill-color: initial;
            }
            &::-moz-placeholder {
                // Firefox 19+
                -webkit-text-fill-color: initial;
            }
            &:-ms-input-placeholder {
                // IE 10+
                -webkit-text-fill-color: initial;
            }
            &:-moz-placeholder {
                // Firefox 18-
                -webkit-text-fill-color: initial;
            }
        }
        &.warning {
            border: 2px solid #ff414d;
        }
    }
    p + p {
        margin-top: 14px;
    }
    .warning + .warning_message {
        display: block;
    }
    .warning_message {
        margin-top: 6px;
        display: none;
        font-size: 14px;
        color: rgba(255, 65, 77, 0.8);
    }
    .info_message {
        $opacity: 0.8;
        margin-top: 10px;
        display: none;
        width: 100%;
        color: rgba(43, 222, 115, $opacity);
        font-size: 14px;
        font-weight: 400;

        &.on {
            display: block;
        }
    }
}
.rel_account {
    a {
        text-decoration: none;
        color: #687980;
        font-size: 14px;
        cursor: pointer;
    }
}
.side_banner {
    margin: 155px 0 0 30px;
    float: left;

    .img_group {
        width: 267px;
        height: 267px;
        position: relative;
        display: inline-block;
        border: 20px solid $highlight-color;

        img {
            position: absolute;

            &.rt {
                right: -9px;
                bottom: 0;
            }
            &.lt {
                right: -4px;
                bottom: 0;
            }
            &.jt {
                left: 0;
                bottom: 0;
            }
            &.tt {
                left: -10px;
                bottom: 0;
            }
            &.david {
                right: 0;
                bottom: 0;
            }
            &.chloe {
                left: 0;
                bottom: 0;
            }
        }
    }
    .txt_group {
        margin-top: 6px;
        display: block;
        text-align: right;
        font-size: 11px;
        color: #00c047;
    }
}
.btn_group {
    button,
    a {
        width: 100%;
        height: 52px;
        padding: 0;
        font-size: 16px;
        color: #2bde73;
        background-color: #272a2e;
        border: 2px solid #35383c;
    }
    button {
        line-height: 48px;
    }
    a {
        display: inline-block;
        line-height: 50px;
    }
    .btn_success {
        color: #fff;
        border: 2px solid #2bde73;
        background-color: #2bde73;
    }
    .btn_check {
        color: #fff;
    }
    .btn_normal {
        color: #131517;
        border: 2px solid #131517;
        background-color: transparent;
    }
    .disabled {
        color: #fefeff;
        background-color: #bcc2c9;
        border: 2px solid #bcc2c9;
        cursor: default;
    }
}
.progress {
    font-size: 0;

    span {
        width: 108px;
        height: 2px;
        display: inline-block;
        text-indent: -9999px;
        overflow: hidden;
        background-color: #abb6bb;
    }
    span + span {
        margin-left: 5px;
    }
    .on {
        background-color: #2bde73;
    }
}

// popup
.popup_alert {
    .pop_inner {
        width: 400px;
        background-color: #fff;
        color: #000;
    }
    .pop_body {
        padding: 48px 0 42px;
    }
    .pop_foot {
        padding: 0 23px 23px;

        .btn_group {
            margin: 0 -6px;
            font-size: 0;
            .col {
                width: 50%;
                padding: 0 6px;
                display: inline-block;
            }
            .row {
                display: block;

                & + .row {
                    margin-top: 13px;
                }
            }
            a {
                text-decoration: none;
                text-align: center;
            }
            button,
            a {
                font-size: 18px;
            }
        }
    }
    .message {
        font-size: 18px;
        text-align: center;
        line-height: 1.4;

        strong {
            margin-bottom: 30px;
            display: block;
            font-size: 26px;
        }
        i {
            margin-top: 3px;
            font-style: normal;
            font-size: 14px;
            display: block;
        }
    }
}

// login
.login_page_main {
    height: 100%;
    text-align: center;
    white-space: nowrap;
    &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: middle;
    }
    .inner {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
    }
}

.login_form {
    float: left;

    .classic_login_info {
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        margin: 0 0 38px;
        padding: 0 24px;
        color: #434449;
        font-size: 11px;
        img {
            width: 42px;
            margin-right: 10px;
        }
    }

    .input_area {
        margin-top: 40px;
    }
    .rel_account {
        margin-top: 30px;
    }
    .btn_group {
        margin-top: 35px;
    }
    .rel_account {
        overflow: hidden;

        a {
            width: $gutter * 2 + $column * 1;
            float: left;
            text-align: center;

            &:after {
                content: '';
                display: inline-block;
                margin-left: 10px;
                @include retina-sprite($common-icon-gt-group);
            }

            & + a {
                float: right;
            }
        }
    }
}

// signup

.signup_form {
    padding-top: 94px;
    float: left;

    .progress {
        margin: 36px 0 38px;
    }
    .btn_group {
        margin-top: 52px;
    }
    .txt_description {
        margin-top: 10px;
        color: rgba(39, 42, 46, 0.8);
        font-size: 14px;
        line-height: 1.4;
        a {
            display: inline-block;
            color: inherit;
            text-decoration: none;
            border-bottom: 1px solid;
        }
    }
    .mobile_check_area + .btn_group {
        margin-top: 58px;
    }
}

.mobile_check_row {
    position: relative;
    padding-right: 128px;

    .btn_group {
        width: 120px;
        margin-top: 0;
        position: absolute;
        right: 0;
        top: 0;

        button {
            font-size: 14px;
        }
    }
}

// find

.find_form {
    margin: 0 auto;
    padding-top: 84px;

    h2 {
        &:before {
            content: '';
            margin: 0 auto 9px;
            display: block;
            @include retina-sprite($common-logo-real-group);
        }
    }
    h3 {
        strong {
            word-break: break-word;
        }
    }
    .input_area {
        margin-top: 45px;
    }
    & > div > .btn_group {
        margin-top: 58px;
    }
    .result_id {
        margin-top: 72px;
        padding: 0 30px;
    }
    .result_password {
        margin-top: 96px;
    }
}

.result_id {
    h3 {
        span {
            position: relative;
            display: block;

            &:after {
                content: '';
                width: 30px;
                height: 2px;
                margin: 12px auto;
                display: block;
                background-color: #687980;
            }
        }
        strong {
            color: #000;
            font-size: 22px;
        }
    }
    .btn_group {
        margin-top: 73px;
    }
}
.result_password {
    h3 {
        font-size: 22px;
        color: #000;
        line-height: 1.4;
    }
    .btn_group {
        margin-top: 70px;
    }
}

// leave
.leave {
    width: $column * 4 + $gutter * 3;
    margin: 0 auto;
    padding: 120px 30px;

    p {
        margin-top: 46px;
        line-height: 1.4;
        font-size: 18px;
    }

    .btn_group {
        button,
        a {
            width: 232px;
            background-color: #fff;
            border: 2px solid $highlight-color;
        }
        a {
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.marketing-agreement {
    display: flex;

    label {
        font-size: 14px;
        font-weight: 500;
        color: #585858;

        a {
            text-decoration: underline;
            color: #585858;
        }
    }
    input:checked + label .fake_checkbox {
        background: url('https://cf.realclass.co.kr/qualson/images/d6824e6a2de6323077d66ce79d40bf1e');
        background-size: 100% 100%;
    }
    .fake_checkbox {
        cursor: pointer;
        vertical-align: bottom;
        display: inline-block;
        width: 21px;
        height: 21px;
        margin-right: 17px;
        background: url('https://cf.realclass.co.kr/qualson/images/e2a9bb15f44bff67f8bcce1d73821521');
        background-size: 100% 100%;
    }
}

.marketing-text {
    margin-top: 22px;
    font-size: 12px;
    font-weight: normal;
    text-align: left;

    color: #707276;
}
