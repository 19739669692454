// reset
@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('AppleSDGothicNeo'), url(../fonts/AppleSDGothicNeo_light_subset.woff2) format('woff2'),
        url(../fonts/AppleSDGothicNeo_light_subset.woff) format('woff');
}
@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('AppleSDGothicNeo'), url(../fonts/AppleSDGothicNeo_regular_subset.woff2) format('woff2'),
        url(../fonts/AppleSDGothicNeo_regular_subset.woff) format('woff');
}
@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('AppleSDGothicNeo'), url(../fonts/AppleSDGothicNeo_bold_subset.woff2) format('woff2'),
        url(../fonts/AppleSDGothicNeo_bold_subset.woff) format('woff');
}

@mixin tooltip($padding: 8px 9px 7px) {
    padding: $padding;
    font-size: 12px;
    color: #fff;
    background-color: $primary-color;
    white-space: nowrap;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #d2d2d2;
    border-radius: 2px;
}
@mixin multiline-ellipsis($height, $lines) {
    max-height: $height;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    webkit-box-orient: vertical;
    word-wrap: break-word;
    white-space: normal;
}
@mixin image-2x($image) {
    @media (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 2.6/2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
        background-image: url($image);
    }
}

html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
body {
    margin: 0;
    font-size: $base-size;
    font-family: 'AppleSDGothicNeo', AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움',
        'Apple SD Gothic Neo', sans-serif;
    color: $primary-color;
    br {
        //ie 개행문자 표기 오류 대응
        font-family: AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', 'Apple SD Gothic Neo',
            sans-serif;
    }
}
* {
    box-sizing: inherit;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

ul,
li,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
span {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}
table {
    border-collapse: collapse;
}
button {
    border-radius: 0;
    background-color: transparent;
}
select,
button {
    cursor: pointer;
    outline: none;
    * {
        cursor: pointer;
        outline: none;
    }
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
    color: $primary-color;

    &::-webkit-input-placeholder {
        // Chrome/Opera/Safari
        color: #858a91;
    }
    &::-moz-placeholder {
        // Firefox 19+
        color: #858a91;
    }
    &:-ms-input-placeholder {
        // IE 10+
        color: #858a91;
    }
    &:-moz-placeholder {
        // Firefox 18-
        color: #858a91;
    }
}
input[type='text'],
input[type='submit'],
input[type='search'] {
    margin: 0;
    outline: none;
}

input[type='submit'] {
    cursor: pointer;
}
input:active,
input:focus {
    outline: none;
}
a[role='button'] {
    cursor: pointer;
    outline: none;
    * {
        cursor: pointer;
        outline: none;
    }
}

select::-ms-expand {
    display: none;
}

a[role='button'] {
    text-decoration: none;
    color: inherit;
    outline: none;
}

img {
    border: none;
    -webkit-touch-callout: none;
}

input[type='search'] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

select[disabled='disabled'] {
    cursor: default !important;
}

.a11y-hidden {
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    &.focusable {
        overflow: visible;
        position: static;
        clip: auto;
        width: auto;
        height: auto;
        margin: 0;
    }
}

.clearfix:after {
    content: '';
    display: block;
    clear: both;
}

.ir {
    text-indent: -9999px;
    overflow: hidden;
}

.txtL {
    text-align: left;
}

.txtR {
    text-align: right;
}
