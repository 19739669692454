/*! Copyright Qualson inc. */
@charset "utf-8";
@import '_reset';
// Vendor
@import './vendors/common-sprite';

@mixin multiline-ellipsis($height, $lines) {
  max-height: $height;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin resize($property_name, $size) {
  #{$property_name}: $size * 0.8;
}

@mixin resize-multiple(
  $property_name,
  $size-top,
  $size-right,
  $size-bottom,
  $size-left
) {
  #{$property_name}: $size-top * 0.85 $size-right * 0.85 $size-bottom * 0.85 $size-left * 0.85;
}

html,
body,
.wrap {
  width: 100%;
  height: 100%;
}

.inner {
  width: $base-width;
  margin: 0 auto;
  position: relative;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501;
  //background-color: $primary-color;
}

// popup
.modal {
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 502;
  }
}

[class^='popup_'] {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  background-color: rgba(0, 0, 0, 0.2);

  &.show {
    display: block;
  }

  .inner {
    height: 100%;
  }

  &.scroll_view {
    overflow-y: scroll;

    .inner {
      height: auto;
      min-height: 100%;
      padding-top: 110px;
      padding-bottom: 110px;
    }

    .pop_inner {
      margin: 0 auto;
      max-width: 100%;
    }
  }

  &.float_view {
    .pop_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }

  .btn_pop {
    padding: 22px 0 21px;
    background-color: $active-color;
    color: #fff;
    font-size: 22px;
    border: none;

    &.primary {
      background-color: #aaaeb2;
    }
  }

  .pop_title {
    border-bottom: 1px solid;
  }

  .btn_close_popup {
    width: 20px;
    height: 20px;
    border: none;
  }
}

.img_round {
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;

  img {
    display: block;
  }
}

.slides {
  position: relative;

  .controller {
    &.btn_black {
      span {
        @include retina-sprite($common-btn-prev-black-group);
      }
    }

    &.btn_fill {
      span {
        @include retina-sprite($common-btn-prev-fill-group);
      }

      &:hover {
        span {
          @include retina-sprite($common-btn-prev-fill-h-group);
        }
      }
    }

    button {
      position: absolute;
      display: none;
      border: none;
      background-color: transparent;

      &.on {
        display: block;
      }
    }

    span {
      display: inline-block;
    }

    .btn_next {
      span {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}

.pad0 {
  padding: 0 !important;
}
